import React from 'react';
import { withCookies } from "react-cookie";
import MaterialTable from 'material-table';
import { getAnalytics } from '../network/Featurelmslists'
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    theme: theme,
    root: {
        display: 'flex',
    },
});

class Featurelmslist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: '',
            open: false,
            data: [],
            showLoading: true,
            // data: { brand: [], model: [], mboard: [] },
            policies: this.props.cookies.cookies.policy != undefined ? JSON.parse(this.props.cookies.cookies.policy) : null,
        };
        console.log(this.props.cookies);
    }

    componentDidMount() {
        getAnalytics(data => {
            console.log(data)
            if (data != null) {
                var active = 0;
                var deactive = 0;
                var total = 0;
                data.map(row => {
                    total += row.total
                })
                data.push({ features: 'Total', total: total })
                this.setState({ data: data, showLoading: false })
                console.log(this.state.data)
            }
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <MaterialTable
                    title={'Feature LMS Analysis'}
                    isLoading={this.state.showLoading}
                    options={
                        {
                            exportAllData: true,
                            exportButton: true,
                            exportDelimiter: ',',
                            exportFileName: 'FeatureList_Analysis',
                            pageSize: 50,
                            loadingType: "overlay",
                        }
                    }
                    /*
                    detailPanel={rowData => {
                        if (rowData.total != 'Total')
                            var data = []
                        rowData._id.map(v => {
                            var temp = ''
                            if (v.features && Array.isArray(v.features)) {
                                v.features.map(i => {
                                    temp += " " + i
                                })
                                v.features = temp
                            }
                            data.push(v)
                        })
                        return (
                            <MaterialTable
                                style={{ marginLeft: 16, marginBottom: 8 }}
                                title={'features[0]'}
                                options={
                                    {
                                        toolbar: false,
                                        search: false,
                                        paging: false,
                                        padding: "dense",
                                    }
                                }
                                columns={[
                                    { title: 'Brand', field: 'brand' },
                                    { title: 'Activated', field: 'activated' },
                                    { title: 'Deactivated', field: 'deactivated' },
                                    { title: 'Total', field: 'total' },
                                ]}
                                data={data}
                            />
                        )
                    }}*/
                    //onRowClick={(event, rowData, toggleDetailPanel) => rowData.features != 'Total' ? toggleDetailPanel() : ''}

                    columns={[
                        { title: 'Features', field: 'features' },
                        { title: 'Brand', field: 'brand' },
                        //{ title: 'Activated', field: 'activated' },
                        //{ title: 'Deactivated', field: 'deactivated' },
                        { title: 'Total', field: 'total' },
                    ]}

                    data={this.state.data}
                />
            </React.Fragment>

        );
    }
}
export default withStyles(styles)(withCookies(Featurelmslist));