import React from "react";
import "../../../App.css";
import "../../../Shineout.css";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import { withCookies } from "react-cookie";
import { routePermissions } from "../../auth/network/Login";
import { bundleUpload } from "../network/Authwalls";
import { get as getResources } from "../network/Resources";
import { get as getFeatures } from "../network/Features";
import { Form, Card, Message, Upload } from "shineout";

const service = "authwalls";
// const path = ['m', 'u'];
const path = "upload";
const UPLOAD_URL = "/lm/v1/authwalls/upload";
const styles = (theme) => ({
	theme: theme,
	root: {
		display: "wrap",
	},
	container: {
		display: "block",
		flexWrap: "wrap",
	},
	fab: {
		margin: theme.spacing(1),
	},
	box: {
		component: "div",
		display: "inline",
		flexWrap: "wrap",
	},
	card: {
		padding: theme.spacing(1),
		margin: theme.spacing(2),
	},
	textField: {
		margin: 8,
		padding: theme.spacing(1),
		width: 200,
	},
	media: {
		paddingTop: "56.25%",
	},
});

class BundleUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{ title: "EMAC", field: "emac" },
				{ title: "MBOARD", field: "mboard" },
				{ title: "SERIAL NO", field: "serialno" },
				{ title: "MODEL", field: "model" },
				{ title: "BRAND", field: "brand" },
			],
			data: [],
			policy: JSON.parse(this.props.cookies.cookies.policy),
			xlsx_data: [],
			table: "",
			isLoading: true,
			POST: false,
			GET: false,
			PUT: false,
			DELETE: false,
			isClicked: false,
		};
	}

	componentWillMount() {
		routePermissions(
			this.props,
			{ service: service, path: path },
			(result) => {
				console.log(result);
				if (result === null) {
					Message.error("No permission to access this route", 3, {
						position: "bottom-right",
					});
				} else {
					result.map((method) => {
						this.setState({ [method.name]: true });
						this.setState({ [method.name + "_DATA"]: method.resources });
					});
					if (this.state.POST) {
						getResources({ type: "brand" }, (data) => {
							console.log(data);
							var brands = [];
							data.map((brand_element) => {
								brands.push(brand_element.name);
								this.setState({ brands: brands });
							});
						});
						getResources({ type: "mboard" }, (data) => {
							console.log(data);
							var boards = [];
							data.map((board_element) => {
								boards.push(board_element.name);
								this.setState({ boards: boards });
							});
						});
						getResources({ type: "vendor" }, (data) => {
							console.log(data);
							var vendors = [];
							data.map((vendor_element) => {
								vendors.push(vendor_element.name);
								this.setState({ vendors: vendors });
							});
						});
						getFeatures({}, (data) => {
							console.log(data)
							var features = [];
							data.map(e => features.push(e.name))
							this.setState({ features: features })
						});
					}
				}
			}
		);
	}

	render() {
		return (
			<React.Fragment>
				<Card fullWidth>
					<Card.Header>Bundle Upload</Card.Header>
					{this.state.POST ? (
						<Card.Body>
							<Form
								scrollToError={30}
								onSubmit={(data) => {
									var request_data = {
										state: "1",
										description: "",
										factory: "",
										vendor: "",
										emacs: this.state.xlsx_data,
									};
									this.setState({ isClicked: true });
									console.log(request_data);
									bundleUpload(request_data, (status) => {
										this.setState({ isClicked: false });
										if (status) {
											Message.success("Records added", 3, {
												position: "bottom-right",
											});
										} else {
											Message.error("Failed saving data. Try again", 3, {
												position: "bottom-right",
											});
										}
									});
								}}
							>
								<Upload
									action={UPLOAD_URL}
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									name="file"
									// headers={{ Authorization: "Bearer " + this.state.token }}
									params={{
										brand: this.state.brands,
										board: this.state.boards,
										vendor: this.state.vendors,
										feature: this.state.features,
									}}
									htmlName={"file"}
									limit={1}
									renderResult={(f) => f}
									onSuccess={(res, file, data) => {
										res = JSON.parse(res);
										console.log(res);
										if (res.errorCode) {
											if (res.errorCode >= 105) {
												this.setState({ data: res.data });
												this.setState({
													table: (
														<MaterialTable
															title={
																res.errorMessage +
																"!! Fix entries below to continue"
															}
															columns={this.state.columns}
															data={this.state.data}
															onRowClick={(
																event,
																rowData,
																toggleDetailPanel
															) => {
																console.log(rowData);
															}}
															options={{
																pageSize:
																	res.data.length < 100 ? res.data.length : 100,
																pageSizeOptions: [20, 50, 100, 200, 300, 500],
																padding: "dense",
																grouping: true,
																filtering: true,
																exportAllData: true,
																exportButton: true,
																exportDelimiter: ",",
																exportFileName: "LMS_ERROR_DATA",
															}}
														/>
													),
												});
												return new Error();
											} else {
												this.setState({ table: "" });
												return new Error(res.errorMessage);
											}
										} else {
											this.setState({ xlsx_data: res });
											this.setState({ data: [] });
											this.setState({ table: "" });
											return "Data parsed, Total entries: " + res.length;
										}
									}}
									validator={{
										ext: (ext) =>
											["xls", "xlsx"].includes(ext)
												? undefined
												: new Error("File extension must be xls or xlsx"),
									}}
								>
									<Upload.Dragger>
										<i className="material-icons">cloud_upload</i>
										<br />
										Click or Drag application to upload
										<br />
										Upload Excel file
									</Upload.Dragger>
								</Upload>
							</Form>
							{this.state.table}
						</Card.Body>
					) : (
							""
						)}
					<Card.Footer>
						<div style={{ textAlign: "left" }}>
							Download XLSX file template <a href="/template.xlsx">here</a>
						</div>
						{this.state.POST ? (
							<Card.Submit
								disabled={this.state.isClicked}
								style={{
									position: "absolute",
									top: 8,
									right: 100,
								}}
							>
								Submit
							</Card.Submit>
						) : (
								""
							)}
					</Card.Footer>
				</Card>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(withCookies(BundleUpload));
