import React from 'react';
import '../../../App.css';
import "../../../Shineout.css";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { get as getLaunchers } from "../network/Resources";
import { create, update, get, delet } from "../network/POCOUNT";
import { getSummaryCount } from "../network/Authwalls";

import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { ImportantDevices, ImportantDevicesRounded } from '@material-ui/icons';
import { Form, Card, Message, Upload, Grid, Input, Select, Rule, Alert } from 'shineout'
import { ControlPointDuplicateOutlined } from '@material-ui/icons';

const service = 'poscount';
const path = '';
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
    
});

class POCOUNTList extends React.Component {
    constructor(props) {
        super(props);
        //var attribfield="LITE_OS";
        this.state = {
            columns: [],
            data: [],            
            total_count: 0,
            used_count: 0,
            remaining_count:0,
            count:{},           
            deactivate_count: 0,
            liteused_count: 0,
            litedeactivate_count:0,
            policy: JSON.parse(this.props.cookies.cookies.policy),
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
        };
    }

    componentDidMount() {
        var columns = [];
        var polist=[];

        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                                        
                    getSummaryCount(count => {
                        if (count != undefined) {
                            //this.setState({ total_count:total_count })
                            console.log("DISPLAY OF REGULAR COUNT",typeof(count))
                            //console.log(count);
                            
                            var index_regular = count['count'].findIndex(x => x.attribute == "REGULAR_OS");
                            console.log("index_regular  REGULAR COUNT",index_regular);
                            if(index_regular >= 0)
                            {
                                var regtotal_count=count['count'][index_regular].quantity;
                                var regused_count=count['count'][index_regular].used;
                                var regremain_count=count['count'][index_regular].remaining;

                            }else{
                                var regtotal_count=0;
                                var regused_count=0;
                                var regremain_count=0;
                            }
                            
                            
                            //console.log(this.state.total_count);
                            console.log("DISPLAY OF REGULAR COUNT",regtotal_count)
                            this.setState({ total_count: regtotal_count });
                            this.setState({ used_count: regused_count });
                            this.setState({ remaining_count: regremain_count });

                            var index_lite = count['count'].findIndex(x => x.attribute == "LITE_OS");
                            console.log("index_lite  LITE OS", index_lite);
                            if(index_lite >= 0)
                            { 
                                var littotal_count= (count['count'][index_lite].quantity) ? count['count'][index_lite].quantity : 0 ;
                                var litused_count= (count['count'][index_lite].used) ? count['count'][index_lite].used : 0 ;
                                var litremain_count=(count['count'][index_lite].remaining) ? count['count'][index_lite].remaining : 0 ;

                            }else{
                                var littotal_count= 0 ;
                                var litused_count=  0 ;
                                var litremain_count= 0 ;
                            }                           
                            
                            //console.log(this.state.total_count);
                            //console.log("DISPLAY OF LITE COUNT",littotal_count)
                            this.setState({ litetotal_count: littotal_count });
                            this.setState({ liteused_count: litused_count });
                            this.setState({ remaining_litecount: litremain_count });

                            var index_voice = count['count'].findIndex(x => x.attribute == "CLOUDTV_VOICE");
                            console.log("index_voice  VOICE COUNT",index_voice);
                            if(index_voice >= 0)
                            { 
                                var voicetotal_count= (count['count'][index_voice].quantity) ? count['count'][index_voice].quantity : 0 ;
                                var voiceused_count= (count['count'][index_voice].used) ? count['count'][index_voice].used : 0 ;
                                var voiceremain_count=(count['count'][index_voice].remaining) ? count['count'][index_voice].remaining : 0 ;

                            }else{
                                var voicetotal_count= 0 ;
                                var voiceused_count=  0 ;
                                var voiceremain_count= 0 ;
                            }
                            //console.log("DISPLAY OF VOICE COUNT",voicetotal_count)
                            this.setState({ CLOUDTV_VOICElicense_count: voicetotal_count });
                            this.setState({ CLOUDTV_usedVOICElicense_count: voiceused_count });
                            this.setState({ remainCLOUDTV_VOICElicense_count: voiceremain_count });
                            
                        }
                    })
                }
            }
        })
    }

    render() {
        const { classes } = this.props;       
        return (            
            <React.Fragment>
                <Card fullWidth>                         
                    <Card.Body>
                {<Grid gutter={2} style={{ marginBottom: 20 }}>
                <div>
                {this.state.total_count > 1 || this.state.litetotal_count > 1 ? (
                    <div>
                    <Card style={{ marginBottom: 30 , padding: 20}}>
                        <h2>LITE OS SUMMARY</h2>                       
                        {<Grid gutter={2}  width={0.5} style={{ marginBottom: 20 }}>
                        <br/>
                            <Grid width={1}>
                            <h3>TOTAL LICENSES: {this.state.litetotal_count}</h3>
                            </Grid>
                            <br/>    
                            <Grid width={1}>
                                <h3>USED LICENSES: {this.state.liteused_count}</h3>
                            </Grid>
                            <br/>
                            <Grid width={1}>
                                <h3>REMAINING LICENSES: {this.state.remaining_litecount}</h3>
                            </Grid>
                        </Grid>}
                    </Card>
                 
                    <Card style={{ marginBottom: 30 , padding: 20}}>
                        <h2>CLOUDTV VOICE SUMMARY</h2>                     
                        {<Grid gutter={2}  width={0.5} style={{ marginBottom: 20 }}>
                        <br/>
                            <Grid width={1}>
                                <h3>TOTAL LICENSES: {this.state.CLOUDTV_VOICElicense_count}</h3>
                            </Grid>
                            <br/>    
                            <Grid width={1}>
                            <h3>USED LICENSES: {this.state.CLOUDTV_usedVOICElicense_count}</h3>
                            </Grid>
                            <br/>
                            <Grid width={1}>
                            <h3>REMAINING LICENSES: {this.state.remainCLOUDTV_VOICElicense_count}</h3>
                            </Grid>
                        </Grid>}
                    </Card>
                
                    <Card style={{ marginBottom: 30 , padding: 20}}>
                        <h2>REGULAR OS SUMMARY</h2>                       
                        {<Grid gutter={2}  width={0.5} style={{ marginBottom: 20 , padding: 10}}>
                        <br/>
                            <Grid width={1}>
                                <h3>TOTAL LICENSES: {this.state.total_count}</h3>
                            </Grid>
                            <br/>    
                            <Grid width={1}>
                                <h3>USED LICENSES: {this.state.used_count}</h3>
                            </Grid>
                            <br/>
                            <Grid width={1}>
                            <h3>REMAINING LICENSES: {this.state.remaining_count}</h3>
                            </Grid>
                        </Grid>}
                    </Card>
                        </div>
                    ) : (
                            <div class="load"></div>
                    )}
                </div>
                    

                </Grid>}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(withCookies(POCOUNTList));