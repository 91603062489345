import React from 'react';
import '../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { withCookies } from "react-cookie";
import { routePermissions } from "../../auth/network/Login";
import { bundleActivator } from "../network/BundleActivator";
import { get as getResources } from "../network/Resources";
import { Form, Card, Message, Upload, Grid, Input, Select, Rule } from 'shineout'

const service = 'bundle_activator';
const path = '';
const UPLOAD_URL = '/lm/v1/uploader/b';
const rules = Rule();
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class BundleActivator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: 'EMAC', field: 'emac' },
                { title: 'SERIAL NO', field: 'serialno' },
                { title: 'DESCRIPTION', field: 'description' },
            ],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            xlsx_data: [],
            table: '',
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
        };
    }

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            console.log(result)
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.POST) {
                    getResources({ type: 'brand' }, (data) => {
                        console.log(data)
                        var brands = [];
                        data.map(brand_element => {
                            brands.push(brand_element.name)
                            this.setState({ brands: brands })
                        })
                    })
                    getResources({ type: 'mboard' }, (data) => {
                        console.log(data)
                        var boards = [];
                        data.map(board_element => {
                            boards.push(board_element.name)
                            this.setState({ boards: boards })
                        })
                    })
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment >
                <Card fullWidth>
                    <Card.Header>Bundle Activator</Card.Header>
                    {this.state.POST ? <Card.Body>
                        <Form
                            scrollToError={30}
                            onSubmit={data => {
                                var request_data = {
                                    emacs: this.state.xlsx_data,
                                    brand: data.brand,
                                    mboard: data.mboard,
                                    model: data.model,
                                    state: '1'
                                }
                                console.log(request_data)
                                bundleActivator(request_data, status => {
                                    if (status) {
                                        Message.success('Records added', 3, { position: 'bottom-right' });
                                    } else {
                                        Message.error('Failed saving data. Try again', 3, { position: 'bottom-right' });
                                    }

                                })
                            }}>
                            <Grid gutter={2}>
                                <Grid width={1 / 2}>
                                    <Grid gutter={1}>
                                        <Grid width={1}>
                                            <Form.Item
                                                required label="Model">
                                                <Input
                                                    name="model"
                                                />
                                            </Form.Item>
                                        </Grid>
                                        <Grid width={1}>
                                            <Form.Item required label="Brand">
                                                <Select
                                                    name="brand"
                                                    data={this.state.brands}
                                                    rules={[rules.required, rules.isExist]}
                                                    onChange={brand => this.setState({ brand: brand })}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    single />
                                            </Form.Item>
                                        </Grid>
                                        <Grid width={1}>
                                            <Form.Item required label="M-Board">
                                                <Select
                                                    name="mboard"
                                                    data={this.state.boards}
                                                    rules={[rules.required, rules.isExist]}
                                                    onChange={mboard => this.setState({ mboard: mboard })}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    single />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid width={1 / 2}>
                                    <Upload
                                        action={UPLOAD_URL}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        name="file"
                                        // headers={{ Authorization: 'Bearer ' + this.state.token }}
                                        params={{ brand: this.state.brands, board: this.state.boards, selected_brand: this.state.brand, selected_board: this.state.mboard }}
                                        htmlName={'file'}
                                        limit={1}
                                        renderResult={f => f}
                                        onSuccess={(res, file, data) => {
                                            res = JSON.parse(res);
                                            console.log(res);
                                            if (res.errorCode) {
                                                if (res.errorCode >= 105) {
                                                    this.setState({ data: res.data })
                                                    this.setState({
                                                        table: <MaterialTable
                                                            title={res.errorMessage + '!! Fix entries below to continue'}
                                                            columns={this.state.columns}
                                                            data={this.state.data}
                                                            onRowClick={(event, rowData, toggleDetailPanel) => {
                                                                console.log(rowData)
                                                            }}
                                                            options={{
                                                                pageSize: res.data.length < 100 ? res.data.length : 100,
                                                                pageSizeOptions: [20, 50, 100, 200, 300, 500],
                                                                actionsColumnIndex: 100,
                                                            }}
                                                        />
                                                    })
                                                    return new Error()
                                                } else {
                                                    this.setState({ table: '' })
                                                    return new Error(res.errorMessage)
                                                }
                                            } else {
                                                this.setState({ xlsx_data: res })
                                                this.setState({ data: [] })
                                                this.setState({ table: '' })
                                                return 'Data parsed, Total entries: ' + res.length
                                            }

                                        }}
                                        validator={
                                            {
                                                ext: ext => (['xls', 'xlsx'].includes(ext) ?
                                                    undefined :
                                                    new Error('File extension must be xls or xlsx')),
                                            }
                                        }>
                                        <Upload.Dragger>
                                            <i className="material-icons">cloud_upload</i>
                                            <br />
                                            Click or Drag application to upload
                                            <br />
                                            Upload Excel file
                                        </Upload.Dragger>
                                    </Upload>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.table}
                    </Card.Body> : ''}
                    <Card.Footer>
                        <div style={{ textAlign: 'left' }}>
                            Download XLSX file template <a href="/template_min.xlsx">here</a>
                        </div>
                        {this.state.POST ? <Card.Submit
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 100,
                            }}
                        >Submit</Card.Submit> : ''}
                    </Card.Footer>
                </Card>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(withCookies(BundleActivator));
