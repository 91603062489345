import superagent from 'superagent';

const URL = '/lm/v1/blacklists';

export const create = (black_body, callback) => {
    superagent.post(URL)
        .send(black_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(response)
        });
};

export const bundleUpload = (black_body, callback) => {
    superagent.post(URL + '/create_bundle')
        .send(black_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(response)
        });
};

export const get = (query, callback) => {
    superagent.get(URL)
        .query(query)
        .end((err, response) => {
            console.log(response);
            if (err) {
                callback([]);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
        });
};

export const update = (oldValue, newValue, callback) => {
    newValue.old_emac = oldValue.emac;
    superagent.put(URL)
        .send(newValue)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
            console.log(response);
        });
};

export const delet = (emac, callback) => {
    superagent.delete(URL)
        .send({ emac: emac })
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(JSON.stringify(response))
        });
};
