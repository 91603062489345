import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { generate } from "../network/Reports";
import { routePermissions } from '../../auth/network/Login'
import { withCookies } from "react-cookie";
import { format, addDays, subDays } from 'date-fns'
import { CsvBuilder } from 'filefy';
import { Rule, Card, Message, Input, Form, Select, Grid, DatePicker } from 'shineout'
import { get as getResources } from "../network/Resources";
import { get as getFeatures } from "../network/Features";
import { get as getBoards } from "../network/Uiconfigs";
import { create, update, get as getDetails } from "../network/Authwalls";


const service = 'monthreports';
const path = '';
const today = new Date()
const formatStart = 'yyyy-MM-dd 00:00:00'
const formatEnd = 'yyyy-MM-dd 23:59:59'
const rules = Rule();
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            column_templates: {
                emac: { title: 'EMAC', field: 'emac' },
                model: { title: 'Model', field: 'model' },
                brand: { title: 'Brand', field: 'brand' },
                mboard: {
                    title: 'MBoard', field: 'mboard', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                panel: {
                    title: 'Panel', field: 'panel', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                lversion: {
                    title: 'L-Version', field: 'lversion', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                serialno: {
                    title: 'Serial No', field: 'serialno', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                cotaversion: { title: 'COTA', field: 'cotaversion' },
                fotaversion: { title: 'FOTA', field: 'fotaversion' },
                description: {
                    title: 'Description', field: 'description', cellStyle: {
                        maxWidth: 60,
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                accept_version: { title: 'Accept-version', field: 'accept_version' },
                lastacessIP: { title: 'Last IP', field: 'lastacessIP' },
                state: { title: 'State', field: 'state' },
            },
            columns: [],
            data: [],
            policies: JSON.parse(this.props.cookies.cookies.policy),
            fields: [],
            POST_DATA: [],
            field: [],
            isLoading: true,
            filter_data: [],
            date_range: [],
            xlsx_data: [],
            table: '',
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            POST_DATA: [],
            GET_DATA: [],
            PUT_DATA: [],
            DELETE_DATA: [],
            mboards: [],
            brands: [],
            creatable_fields: [],
            editable_fields: [],
            fields: [],
            visible_fields: [],
            isAllVisible: false,
            isAllEditable: false,
        };
    }

    componentWillMount() {
        console.log({ service: service, path: path })
        routePermissions(this.props, { service: service, path: path }, result => {
            console.log(result)
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                    var visible_fields = this.state.GET_DATA.find(e => e.key == 'visible_fields');
                    console.log(visible_fields)
                    if (visible_fields) {
                        if (visible_fields.value.indexOf('*') > -1) {
                            this.setState({ isAllVisible: true });
                        }
                        this.setState({ visible_fields: visible_fields.value });
                    }
                    if (this.state.tv_data)
                        getDetails({ emac: this.state.tv_data.emac }, result => {
                            console.log(result)
                            if (result.length == 0)
                                Message.error('EMAC not resolved', 3, { position: 'bottom-right' });
                            else {
                                this.setState({ tv_data: result[0] })
                                getFeatures({ brands: [result[0].brand] }, (data) => {
                                    console.log(data)
                                    var features = [];
                                    data.map(e => features.push(e.name))
                                    this.setState({ features: features })
                                });

                                console.log("BOARDS CREATE");
                                getBoards({ brand: result[0].brand }, (data) => {
                                    console.log(data);
                                    console.log("^^^^^^^^^^^^^^^^^");
                                    var boards = [];
                                    data.map(e => boards.push(e.board))
                                    this.setState({ boards: boards })
                                });
                                /*
                                getResources({ brands: [result[0].brand] }, (data) => {
                                    console.log(data)
                                    var boards = [];
                                    data.map(board_element => {
                                        boards.push(board_element.name)
                                        this.setState({ boards: boards })
                                    })
                                })*/
                            }
                        })
                    else
                        getFeatures({}, (data) => {
                            console.log(data)
                            var features = [];
                            data.map(e => features.push(e.name))
                            this.setState({ features: features })
                        });

                    getBoards({}, (data) => {
                        console.log(data);
                        console.log("^^^^^^^^^^^^^^^^^------------");
                        var boards = [];
                        data.map(e => boards.push(e.board))
                        this.setState({ boards: boards })
                    });


                }
                if (this.state.POST) {
                    getResources({ type: 'brand' }, (data) => {
                        console.log(data)
                        var brands = [];
                        data.map(brand_element => {
                            brands.push(brand_element.name)
                            this.setState({ brands: brands })
                        })
                    })

                    /*
                    getResources({ type: 'mboard' }, (data) => {
                        console.log(data)
                        var boards = [];
                        data.map(board_element => {
                            boards.push(board_element.name)
                            this.setState({ boards: boards })
                        })
                    })
                    */
                }
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <Card fullWidth>
                    <Card.Body>
                        <h2>Month Report Generation</h2>
                        <Form
                            onSubmit={value => {
                                console.log(value)
                                var data = [];
                                /*value.field.map(field => data.push(this.state.rule_data[field]))
                                */
                                var request_data = {
                                    brand: data.brand,
                                    mboard: data.mboard,
                                    model: data.model,
                                    features: data.features,
                                    factory: data.factory,
                                    vendor: data.vendor,
                                    state: '1'
                                }
                                console.log("display--> fields filter-->", request_data)
                                //console.log(data)
                                this.setState({ filter_data: ['brand', 'emac', 'model', '*'] })
                                var date_range = value.date_range ? value.date_range : ["", ""];
                                if (date_range[0] === '' || date_range[1] === '') {
                                    date_range = undefined;
                                }
                                this.setState({ date_range: date_range })
                                generate({ filters: data, date_range: date_range }, result => {
                                    console.log(result)
                                    if (result == null) {
                                        Message.error('No Data Available', 3, { position: 'bottom-right' });
                                    } else {
                                        this.setState({ data: result })
                                        Message.success('Data Found', 3, { position: 'bottom-right' });
                                    }
                                })
                            }}
                        >
                            <Form.Item required label="Month Report Name">
                                <Input
                                    name="monthreport_name"
                                    fullWidth
                                    onChange={value => this.setState({ report_name: value })}
                                    value={this.state.report_name}
                                    rules={[rules.required, rules.isExist]}
                                />
                            </Form.Item>
                            <Form.Item required label="Fields">
                                <Select
                                    name="field"
                                    fullWidth
                                    style={{ marginRight: 8 }}
                                    data={['emac', 'model', 'brand', '*']}
                                    clearable
                                    keygen
                                    onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                    //rules={[rules.required, rules.isExist]}
                                    multiple />
                            </Form.Item>
                            <Grid gutter={1}>
                                <Grid width={1}>
                                    <Form.Item label="Brand">
                                        <Select
                                            name="brand"
                                            data={this.state.brands}
                                            rules={[rules.isExist]}
                                            //disabled={this.state.isAllEditable || this.state.fields.indexOf('brand') > -1 ? false : true}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            onChange={
                                                value => {
                                                    getFeatures({ brands: [value] }, (data) => {
                                                        console.log(data)
                                                        var features = [];
                                                        data.map(e => features.push(e.name))
                                                        this.setState({ features: features })
                                                    });
                                                    console.log(value);
                                                    getBoards({ brand: [value] }, (data) => {
                                                        console.log('DISPLAY ADDED' + data)
                                                        var boards = [];
                                                        var listboards = [];
                                                        data.map(e => boards.push(e.board + '@' + e.keymd5))
                                                        //this.setState({ boards: boards })
                                                        //var myarray = [];
                                                        //console.log('start->' + boards[i]);
                                                        console.log("LENGTH-->" + boards.length);
                                                        if (boards.length > 0) {
                                                            if (boards.length <= 1) {
                                                                var last = boards[0];
                                                                var keymd5get = last.split('@');
                                                                //listboards.push(keymd5get[0] + '-' + keymd5get[1])
                                                                let text = keymd5get[1];
                                                                let changevalue = text.startsWith("FD889");
                                                                if (changevalue) {
                                                                    listboards.push('(CLOUDTV)' + ' ' + keymd5get[0])
                                                                } else {
                                                                    listboards.push('(OEM)' + ' ' + keymd5get[0])
                                                                }
                                                            }
                                                            else {
                                                                var str = boards;
                                                                console.log(str);
                                                                //var myarray = str.split(',');
                                                                for (var j = 0; j < boards.length; j++) {
                                                                    //console.log('boards-->' + boards[j]);
                                                                    var str = boards[j];
                                                                    var myarray = str.split(',');
                                                                    console.log('values-->' + myarray)
                                                                    console.log('length-->' + myarray.length)
                                                                    console.log('\n\n')
                                                                    if (myarray.length <= 1) {
                                                                        var last = myarray[0];
                                                                        var keymd5get = last.split('@');
                                                                        //listboards.push(keymd5get[0] + '-' + keymd5get[1])
                                                                        //console.log("ONE ARRAY---->" + listboards)
                                                                        let text = keymd5get[1];
                                                                        let changevalue = text.startsWith("FD889");
                                                                        if (changevalue) {
                                                                            listboards.push('(CLOUDTV)' + ' ' + keymd5get[0])
                                                                        } else {
                                                                            listboards.push('(OEM)' + ' ' + keymd5get[0])
                                                                        }
                                                                    } else {
                                                                        var last = myarray.slice(-1)[0];
                                                                        var keymd5get = last.split('@');
                                                                        //console.log("last STRING ARRAy" + keymd5get)
                                                                        var newarray = myarray.pop();
                                                                        myarray.push(keymd5get[0])
                                                                        let text = keymd5get[1];
                                                                        let changevalue = text.startsWith("FD889");
                                                                        let signaturetext;
                                                                        if (changevalue) {
                                                                            signaturetext = '(CLOUDTV)' + ' ';
                                                                        } else {
                                                                            signaturetext = '(OEM)' + ' ';
                                                                        }
                                                                        myarray = myarray.map(c => signaturetext + c);
                                                                        //console.log("final ARRAY%%%%%%%>" + myarray)
                                                                        listboards.push.apply(listboards, myarray);
                                                                        //console.log(" MULTI ARRAY ---->" + listboards)
                                                                    }
                                                                }

                                                            }
                                                        } else {
                                                            listboards.push()
                                                        }
                                                        let uniqueChars = [];
                                                        listboards.forEach((c) => {
                                                            if (!uniqueChars.includes(c)) {
                                                                uniqueChars.push(c);
                                                            }
                                                        });
                                                        uniqueChars.sort();
                                                        this.setState({ boards: uniqueChars })
                                                    });
                                                    this.setState({ brand: value })
                                                }}
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1}>
                                    <Form.Item label="Board">
                                        <Select
                                            name="mboard"
                                            data={this.state.boards}
                                            rules={[rules.isExist]}
                                            onChange={mboard => this.setState({ mboard: mboard })}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            single />
                                    </Form.Item>
                                </Grid>

                                <Grid width={1}>
                                    <Form.Item label="Features">
                                        <Select
                                            name="features"
                                            data={this.state.features}
                                            rules={[rules.isExist]}
                                            clearable
                                            keygen
                                            onChange={feature => this.setState({ feature: feature })}
                                            onFilter={text => d =>
                                                d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                            multiple />
                                    </Form.Item>
                                </Grid>
                            </Grid>
                            <Form.Item label="Date between">
                                <DatePicker
                                    name="date_range"
                                    range
                                    onChange={d => console.log(d)}
                                    type="datetime"
                                    inputable
                                    quickSelect={[
                                        {
                                            name: 'Today',
                                            value: [Date.now(), Date.now()],
                                        },
                                        {
                                            name: 'Next week',
                                            value: [format(today, formatStart), format(addDays(today, 7), formatEnd)],
                                        },
                                        {
                                            name: 'Last week',
                                            value: [format(subDays(today, 7), formatStart), format(today, formatEnd)],
                                        },
                                        {
                                            name: 'Next month',
                                            value: [format(today, formatStart), format(addDays(today, 30), formatEnd)],
                                        },
                                        {
                                            name: 'Last month',
                                            value: [format(subDays(today, 30), formatStart), format(today, formatEnd)],
                                        },
                                    ]}
                                    style={{ marginTop: '12px' }}
                                />
                            </Form.Item>
                        </Form>

                        <Card.Submit
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                width: '100%',
                            }}
                        >Search</Card.Submit>

                        <MaterialTable
                            title={'Monthreport'}
                            columns={this.state.columns}
                            data={this.state.data}
                            options={{
                                grouping: true,
                                filtering: true,
                                padding: 'dense',
                                pageSize: 20,
						        pageSizeOptions: [20, 50, 100, 150, 500],
                                exportAllData: true,
                                exportButton: true,
                                exportDelimiter: ',',
                                exportCsv: (columns, renderData) => {
                                    columns = columns.filter(columnDef => {
                                        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
                                    })
                                    const data = [];
                                    renderData.map(rowData => {
                                        var temp = [];
                                        columns.map(columnDef => {
                                            temp.push(rowData[columnDef.field]);
                                        })
                                        data.push(temp)
                                    });
                                    data.push([])
                                    data.push(['Date Range', this.state.date_range]);
                                    data.push(['FILTERS']);
                                    this.state.filter_data.map(filter => {
                                        data.push(['', filter.field.toUpperCase(), filter.operator === 'IN' ? 'INCLUDING' : 'EXCLUDING', filter.values]);
                                    })
                                    data.push(['TOTAL: ' + renderData.length]);
                                    new CsvBuilder(this.state.report_name + '.csv')
                                        .setDelimeter(this.props.exportDelimiter)
                                        .setColumns(columns.map(columnDef => columnDef.title))
                                        .addRows(data)
                                        .exportFile();
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}



export default withStyles(styles)(withCookies(Reports));
