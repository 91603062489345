import superagent from 'superagent';

const URL = '/lm/v1/resources';

export const create = (resource_body, callback) => {
    superagent.post(URL)
        .send(resource_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(response)
        });
};

export const get = (query, callback) => {
    superagent.get(URL)
        .query(query)
        .end((err, response) => {
            if (err) {
                callback([]);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
            console.log(response);
        });
};

export const update = (oldValue, newValue, callback) => {
    newValue.old_name = oldValue.name;
    superagent.put(URL)
        .send(newValue)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
            console.log(response);
        });
};

export const delet = (name, callback) => {
    superagent.delete(URL)
        .send({ name: name })
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(JSON.stringify(response))
        });
};
