import React from "react";
import { withCookies } from "react-cookie";
import "../../App.css";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { red } from "@material-ui/core/colors";
import Popover from "@material-ui/core/Popover";
import withStyles from "@material-ui/core/styles/withStyles";
import Sidebar from "./Sidebar";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import History from "../commons/History";
import UiConfigs from "./list/UiConfigs";
import AttributeList from "./list/AttributeList";
import POCOUNTList from "./list/POCOUNTList";
//import SkintvLaunchers from "./list/SkintvLaunchers";
import Users from "./list/Users";
import User from "./pages/User";
import BundleUpload from "./list/BundleUpload";
import TVList from "./list/TVList";
import Blacklists from "./list/Blacklists";
import Policies from "./list/Policies";
import BrandList from "./list/BrandList";
import VendorList from "./list/VendorList";
import FactoryList from "./list/FactoryList";
import Actions from "./list/Actions";
import Services from "./list/Services";
import POList from "./list/POList";
import Service from "./pages/Service";
import Policy from "./pages/Policy";
import Logs from "./list/Logs";
import TVDetails from "./pages/TVDetails";
import FeatureList from "./list/FeatureList";
import SkintvlauncherList from "./list/SkintvlauncherList";
import { Message, Grid } from "shineout";
import {
	getAllowedLicenseCount,
	getUsedLicenseCount,
} from "./network/Authwalls";
import BoardList from "./list/BoardList";
import About from "./pages/About";
import TestSuite from "./pages/TestSuite";
import Reports from "./pages/Reports";
import FirmwaresList from "./list/FirmwaresList";
import BundleUpdate from "./list/BundleUpdate";
import BundleActivator from "./list/BundleActivator";
import CsvbundleUploader from "./list/CsvbundleUploader";
import { logout as deleteCookies } from "../auth/Login";
import { logoutRoute } from "../auth/network/Login";
import Feature from "./pages/Feature";
import Skintvlauncher from "./pages/Skintvlauncher";
import Featurelmslists from "./pages/Featurelmslists";
import Launcherlmslists from "./pages/Launcherlmslists";
import Monthreports from "./pages/Monthreports";

const drawerWidth = 210;
const styles = (theme) => ({
	theme: theme,
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	avatar: {
		backgroundColor: red[500],
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	userMenuButton: {
		marginRight: theme.spacing(2),
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
});

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		console.log(this.props.cookies)
		this.state = {
			component: "",
			open: true,
			router: <Router history={History} />,
			anchorEl: null,
			openProfile: false,
			total_count: 0,
			used_count: 0,
			username: this.props.cookies.cookies.username,
			policy: JSON.parse(this.props.cookies.cookies.policy),
		};
		this.handleDrawerOpen = this.handleDrawerOpen.bind();
		this.handleDrawerClose = this.handleDrawerClose.bind();
	}

	componentDidMount() {
		if (this.props.cookies.cookies.policy) {
			var policy = JSON.parse(this.props.cookies.cookies.policy);
			var actions = [];
			policy.map((action) => {
				switch (action) {
					case "authwalls":
						actions.push(
							<Route key="bundle_upload" path={"/bundle_upload"} component={BundleUpload} />
						);
						actions.push(
							<Route key="device_details" path={"/device_details"} component={TVDetails} />
						);
						actions.push(<Route key="authwalls" path={"/authwalls"} component={TVList} />);
						break;
					case "uploader":
						actions.push(<Route key="updater" path={"/updater"} component={BundleUpdate} />);
						break;
					case "bundle_activator":
						actions.push(
							<Route key="bundle_activator" path={"/bundle_activator"} component={BundleActivator} />
						);
						break;
					case "csvbundle_uploader":
						actions.push(
							<Route key="csvbundle_uploader" path={"/csvbundle_uploader"} component={CsvbundleUploader} />
						);
						break;
					case "reports":
						actions.push(<Route key="reports" path={"/reports"} component={Reports} />);
						break;
					case "test_suite":
						actions.push(<Route key="test" path={"/test"} component={TestSuite} />);
						break;
					case "blacklists":
						actions.push(<Route key="blacklists" path={"/blacklists"} component={Blacklists} />);
						break;
					case "users":
						actions.push(<Route key="users" path={"/users"} component={Users} />);
						actions.push(<Route key="user" path={"/user"} component={User} />);
						break;
					case "policies":
						actions.push(<Route key="policy" path={"/policy"} component={Policy} />);
						actions.push(<Route key="policies" path={"/policies"} component={Policies} />);
						break;
					case "actions":
						actions.push(<Route key="actions" path={"/actions"} component={Actions} />);
						break;
					case "services":
						actions.push(<Route key="service" path={"/service"} component={Service} />);
						actions.push(<Route key="services" path={"/services"} component={Services} />);
						break;
					case "uiconfigs":
						actions.push(<Route key="uiconfigs" path={"/uiconfigs"} component={UiConfigs} />);
						break;
					case "firmwares":
						actions.push(
							<Route key="firmwares" path={"/firmwares"} component={FirmwaresList} />
						);
						break;
					case "resources":
						actions.push(<Route key="boards" path={"/boards"} component={BoardList} />);
						actions.push(<Route key="resources" path={"/resources"} component={BrandList} />);
						actions.push(<Route key="vendors" path={"/vendors"} component={VendorList} />);
						actions.push(<Route key="factorys" path={"/factorys"} component={FactoryList} />);
						actions.push(<Route key="attributes" path={"/attributes"} component={AttributeList} />);
						break;
					case "features":
						actions.push(<Route key="features" path={"/features"} component={FeatureList} />);
						actions.push(<Route key="feature" path={"/feature"} component={Feature} />);
						break;
					case "skintvlaunchers":
						actions.push(<Route key="skintvlaunchers" path={"/skintvlaunchers"} component={SkintvlauncherList} />);
						actions.push(<Route key="skintvlauncher" path={"/skintvlauncher"} component={Skintvlauncher} />);
						break;
					case "pos":
						actions.push(<Route key="pos" path={"/pos"} component={POList} />);
						break;
					case "poscount":
						actions.push(<Route key="poscount" path={"/poscount"} component={POCOUNTList} />);
						break;	
					case "logs":
						actions.push(<Route key="logs" path={"/logs"} component={Logs} />);
						break;
				}
			});
			actions.push(<Route key="root" exact path="/" component={Home} />);
			actions.push(<Route key="featurelmslists" exact path="/featurelmslists" component={Featurelmslists} />);
			actions.push(<Route key="launcherlmslists" exact path="/launcherlmslists" component={Launcherlmslists} />);
			actions.push(<Route key="about" exact path="/about" component={About} />);
			// actions.push(<Redirect to="/" />)

			this.setState({
				router: <Router history={History}>
					<Switch>{actions}</Switch>
				</Router>
			});
			getAllowedLicenseCount((result) => {
				if (result != undefined) {
					this.setState(result);
				}
			});
			getUsedLicenseCount((result) => {
				if (result != undefined) {
					this.setState(result);
				}
			});
		}
	}

	handleDrawerOpen = (event) => {
		this.setState({ open: true });
	};

	handleDrawerClose = (event) => {
		this.setState({ open: false });
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: this.state.open,
					})}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={this.handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton, {
								[classes.hide]: this.state.open,
							})}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap>
							<strong>
								Cloudwalker License Management System
							</strong>
						</Typography>
						{/*
						<Tooltip title="Click to Refresh" aria-label="Click to Refresh" >
							<Typography
								noWrap
								style={{
									position: "absolute",
									right: 100,
								}}
								onClick={() => {
									getAllowedLicenseCount((result) => {
										if (result != undefined) {
											this.setState(result);
										}
									});
									getUsedLicenseCount((result) => {
										if (result != undefined) {
											this.setState(result);
										}
									});
								}}
							>
								LICENSES: TOTAL {this.state.total_count} |{" "}
								{this.state.used_count} USED |{" "}
								{this.state.total_count - this.state.used_count} REMAINING
							</Typography>
						</Tooltip>
						*/}
						
						{
						/*OLD CODE
						/*
						<IconButton
							style={{
								position: "absolute",
								top: 10,
								right: 10,
							}}
							className={classes.userMenuButton}
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={(event) => {
								this.setState({ anchorEl: event.currentTarget });
								this.setState({ openProfile: true });
							}}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>

						<Popover
							id={"simple-popover"}
							open={this.state.openProfile}
							anchorEl={this.state.anchorEl}
							onClose={(event) => {
								this.setState({ anchorEl: null });
								this.setState({ openProfile: false });
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label="user" className={classes.avatar}>
											U
										</Avatar>
									}
									title={"Hello " + this.props.cookies.cookies.username}
								/>
								<CardActions>
									<Button
										size="small"
										color="primary"
										onClick={(event) => {
											console.log("Logout clicked");
											logoutRoute(status => {
												History.replace('/')
												window.location.reload();
											})
										}}
									>
										Logout
									</Button>
								</CardActions>
							</Card>
						</Popover>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: this.state.open,
						[classes.drawerClose]: !this.state.open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: this.state.open,
							[classes.drawerClose]: !this.state.open,
						}),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbar}>
						<IconButton onClick={this.handleDrawerClose}>
							{<ChevronLeftIcon />}
						</IconButton>
					</div>
					<Divider />
					<List>
						<Sidebar />
					</List>
					<Divider />
				</Drawer>
				<div className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.router}
				</div>
				*/}
				<IconButton
							style={{
								position: "absolute",
								top: 10,
								right: 10,
							}}
							className={classes.userMenuButton}
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={(event) => {
								this.setState({ anchorEl: event.currentTarget });
								this.setState({ openProfile: true });
							}}
							color="inherit"
						>{" " + this.props.cookies.cookies.username +" "}
							<AccountCircle />
						</IconButton>

						<Popover
							id={"simple-popover"}
							open={this.state.openProfile}
							anchorEl={this.state.anchorEl}
							onClose={(event) => {
								this.setState({ anchorEl: null });
								this.setState({ openProfile: false });
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							<Card className={classes.card}>
								
								<CardActions>
									<Button
										size="small"
										color="primary"
										onClick={(event) => {
											console.log("Logout clicked");
											logoutRoute(status => {
												History.replace('/')
												window.location.reload();
											})
										}}
									>
										Logout
									</Button>
								</CardActions>
							</Card>
						</Popover>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: this.state.open,
						[classes.drawerClose]: !this.state.open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: this.state.open,
							[classes.drawerClose]: !this.state.open,
						}),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbar}>
						<IconButton onClick={this.handleDrawerClose}>
							{<ChevronLeftIcon />}
						</IconButton>
					</div>
					<Divider />
					<List>
						<Sidebar />
					</List>
					<Divider />
				</Drawer>
				<div className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.router}
				</div>
			</div>
		);
	}
}
export default withStyles(styles)(withCookies(Dashboard));
