import React from "react";
import "../../../App.css";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import { create, update, get, delet } from "../network/Uiconfigs";
import { get as getBrands } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Message } from "shineout";

const service = "uiconfigs";
const path = "";
const styles = theme => ({
	theme: theme,
	root: {
		display: "wrap"
	},
	container: {
		display: "block",
		flexWrap: "wrap"
	},
	fab: {
		margin: theme.spacing(1)
	},
	box: {
		component: "div",
		display: "inline",
		flexWrap: "wrap"
	},
	card: {
		padding: theme.spacing(1),
		margin: theme.spacing(2)
	},
	textField: {
		margin: 8,
		padding: theme.spacing(1),
		width: 200
	},
	media: {
		paddingTop: "56.25%"
	}
	
	
});

class UiConfigs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{ title: "Brand", field: "brand" },
				{ title: "Board", field: "board" },
				{ title: "UiVersion", field: "uiVersion" },
				{ title: "KEY MD5", field: "keymd5" },
				{ title: "MD5", field: "md5" },				
				{
					title: "Download Url",
					field: "downloadUrl",
					cellStyle: {
						overflow: "hidden",
						"text-overflow": "ellipsis",
						"white-space": "nowrap"
					}
				},
				{ title: "Default Key", field: "defaultKey", type: "boolean" },
				{ title: "Use OTA", field: "useOta", type: "boolean" },
			],
			data: [],
			policy: JSON.parse(this.props.cookies.cookies.policy),
			isLoading: true,
			POST: false,
			GET: false,
			PUT: false,
			DELETE: false
		};
	}

	componentDidMount() {
		var columns = [];
		getBrands({ type: "brand" }, data => {
			console.log(data);
			var brand_names = {};
			data.map(e => (brand_names[e.name] = e.name));
			this.state.columns.map(e => {
				if (e.field == "brand") {
					e.lookup = brand_names;
					console.log(brand_names);
					columns.push(e);
				} else {
					columns.push(e);
				}
			});
			console.log(columns);
			this.setState({ columns: columns });
		});

		routePermissions(
			this.props,
			{ service: service, path: path },
			result => {
				if (result === null) {
					this.setState({ isError: true });
				} else {
					result.map(method => {
						this.setState({ [method.name]: true });
						this.setState({ [method.name + "_DATA"]: method.resources });
					});
					if (this.state.GET) {
						get({}, data => {
							console.log(data);
							data.map(config => {
								var temp = "";
								config.board.map(board => (temp += board + "\n"));
								config.board = temp;
							});
							this.setState({ data: data });
							this.setState({ isLoading: false });
						});
					}
				}
			}
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<MaterialTable
					title={"UI-Configs"}
					columns={this.state.columns}
					data={this.state.data}
					isLoading={this.state.isLoading}
					options={{
						loadingType: "overlay",
						padding: "dense",
						addRowPosition: "first",
						filtering: true,
						grouping: true,
						pageSize: 50,
						pageSizeOptions: [50, 100, 150, 200 , 500],
						actionsColumnIndex: 100,
						rowStyle: rowData => ({
							backgroundColor:
								rowData.tableData.id % 2 == 0 ? "#F1F3F4" : "#FFF"
						})
					}}
					editable={{
						onRowAdd: this.state.POST
							? newData =>
								new Promise(resolve => {
									newData.board = newData.board.split(",");
									create(newData, result => {
										if (result)
											get({}, data => {
												this.setState({ data: data });
												Message.success("UIConfig created", 3, {
													position: "bottom-right"
												});
												resolve();
											});
										else {
											Message.error(
												"Failed to create UIConfig. Try again",
												3,
												{ position: "bottom-right" }
											);
											resolve();
										}
									});
								})
							: undefined,
						onRowUpdate: this.state.PUT
							? (newData, oldData) =>
								new Promise(resolve => {
									newData.board =
										newData.board.indexOf(",") > -1
											? newData.board.split(",")
											: newData.board
												.replace("\n", " ")
												.trim()
												.split(" ");
									update(oldData, newData, result => {
										if (result)
											get({}, data => {
												this.setState({ data: data });
												Message.success("UIConfig updated", 3, {
													position: "bottom-right"
												});
												resolve();
											});
										else {
											Message.error(
												"Failed to update UIConfig. Try again",
												3,
												{ position: "bottom-right" }
											);
											resolve();
										}
									});
								})
							: undefined,
						onRowDelete: this.state.DELETE
							? oldData =>
								new Promise(resolve => {
									delet(
										oldData.brand,
										oldData.md5,
										result => {
											if (result)
												get({}, data => {
													this.setState({ data: data });
													Message.success("UIConfig deleted", 3, {
														position: "bottom-right"
													});
													resolve();
												});
											else {
												Message.error(
													"Failed to delete UIConfig. Try again",
													3,
													{ position: "bottom-right" }
												);
												resolve();
											}
										}
									);
								})
							: undefined
					}}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(withCookies(UiConfigs));