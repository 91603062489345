import React from 'react';
import { testOne } from "../network/TestSuite";
import { get } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Card, Message, Input, Rule, Form, Select, Grid } from 'shineout'
const service = 'test_suite';
const path = '';
const rules = Rule();
class TestSuite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            policies: JSON.parse(this.props.cookies.cookies.policy),
            POST: false,
            brands: [],
            mboards: [],
        }
    };

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
            }
        })

        get({ type: 'brand' }, (data) => {
            console.log(data)
            var brands = [];
            data.map(e => brands.push(e.name))
            this.setState({ brands: brands })
        });

        get({ type: 'mboard' }, (data) => {
            console.log(data)
            var mboards = [];
            data.map(e => mboards.push(e.name))
            this.setState({ mboards: mboards })
        });
    }

    render() {
        return (
            <React.Fragment>
                <Card >
                    <Card.Header>
                        <h2>Test Suite</h2>
                    </Card.Header>
                    <Card.Body style={{
                        'text-align': 'left',
                    }}>
                        <Form
                            disabled={!this.state.POST}
                            value={this.state.tv_data}
                            onSubmit={value => {
                                console.log(value)
                                this.state.POST ? testOne(value, result => {
                                    if (result) {
                                        Message.success('Test Successful', 3, { position: 'bottom-right' });
                                        this.setState({ tv_data: value })
                                    }
                                    else {
                                        Message.error('Test Failed', 3, { position: 'bottom-right' });
                                    }
                                }) : Message.error('Permission denied', 3, { position: 'bottom-right' })
                            }}>
                            <Grid gutter={2}>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="EMAC">
                                        <Input
                                            name="emac"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Brand">
                                        <Select
                                            name="brand"
                                            data={this.state.brands}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="M-Board">
                                        <Select
                                            name="mboard"
                                            data={this.state.mboards}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            single />
                                    </Form.Item>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.POST ? <Card.Submit
                            style={{
                                width: '100%',
                            }}
                        >Save</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withCookies(TestSuite);
