import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        maxWidth: 300,
        display: 'block',
        flexWrap: 'wrap',
    },
});

class LoadingDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind();
    }

    handleClose = event => {
        this.props.onDone(event);
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.props.open}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="slide-title"
                    aria-describedby="slide-description">
                    <DialogTitle id="uploading-dialog">{this.props.message}</DialogTitle>
                    <DialogActions>
                        {(this.props.status) ? <Button onClick={this.handleClose} color="primary">
                            Done
                        </Button>:
                            <DialogContent>
                                <LinearProgress/>
                            </DialogContent>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(LoadingDialog);
