import React from 'react';
import '../../../App.css';
import "../../../Shineout.css";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { withCookies } from "react-cookie";
import { routePermissions } from "../../auth/network/Login";
import { csvbundleUploader } from "../network/CsvbundleUploader";
//import { get as getBoards } from "../network/CsvbundleUploader";
import { get as getResources } from "../network/Resources";
import { get as getFeatures } from "../network/Features";
import { get as getBoards } from "../network/Uiconfigs";
import { create, update, get as getDetails } from "../network/Authwalls";
import { Form, Card, Message, Upload, Grid, Input, Select, Rule, Alert } from 'shineout'
import { ControlPointDuplicateOutlined } from '@material-ui/icons';
/*
db.uiconfigs.aggregate({
    $project: {
        board: 1,
        keymd5: 1
    }
}, {
    $unwind: "$board"
}, {
    $group: {
        _id: {
            board: "$board",
            keymd5: "$keymd5"
        },
        info: {
            $addToSet: "$board"
        }
    }
})
*/

const service = 'csvbundle_uploader';
const path = '';
const UPLOAD_URL = '/lm/v1/uploader/c';
const rules = Rule();
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class CsvbundleUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: 'EMAC', field: 'emac' },
                { title: 'SERIAL NO', field: 'serialno' },
                { title: 'DESCRIPTION', field: 'description' },
            ],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            xlsx_data: [],
            table: '',
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            POST_DATA: [],
            GET_DATA: [],
            PUT_DATA: [],
            DELETE_DATA: [],
            mboards: [],
            brands: [],
            vendors: [],
            creatable_fields: [],
            editable_fields: [],
            fields: [],
            visible_fields: [],
            isAllVisible: false,
            isAllEditable: false,

        };
    }

    componentWillMount() {
        
        routePermissions(this.props, { service: service, path: path }, result => {
            console.log(result)
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                    var visible_fields = this.state.GET_DATA.find(e => e.key == 'visible_fields');
                    console.log(visible_fields)
                    if (visible_fields) {
                        if (visible_fields.value.indexOf('*') > -1) {
                            this.setState({ isAllVisible: true });
                        }
                        this.setState({ visible_fields: visible_fields.value });
                    }
                    if (this.state.tv_data)
                        getDetails({ emac: this.state.tv_data.emac }, result => {
                            console.log(result)
                            if (result.length == 0)
                                Message.error('EMAC not resolved', 3, { position: 'bottom-right' });
                            else {
                                this.setState({ tv_data: result[0] })
                                getFeatures({ brands: [result[0].brand] }, (data) => {
                                    console.log(data)
                                    var features = [];
                                    data.map(e => features.push(e.name))
                                    this.setState({ features: features })
                                });

                                console.log("BOARDS CREATE");
                                getBoards({ brand: result[0].brand }, (data) => {
                                    console.log(data);
                                    console.log("^^^^^^^^^^^^^^^^^");
                                    var boards = [];
                                    data.map(e => boards.push(e.board))
                                    this.setState({ boards: boards })
                                });

                                getResources({type: 'vendor'}, (data) => {
                                    console.log(data)
                                    var vendors = [];
                                    data.map(e => vendors.push(e.name))
                                    this.setState({ vendors: vendors })
                                    console.log("======VENDORS=====>>>",vendors)
                                });

                                getResources({type: 'factory'}, (data) => {
                                    console.log(data)
                                    var factorys = [];
                                    data.map(e => factorys.push(e.name))
                                    this.setState({ factorys: factorys })
                                    console.log("======factorys=====>>>",factorys)
                                });
                                /*
                                getResources({ brands: [result[0].brand] }, (data) => {
                                    console.log(data)
                                    var boards = [];
                                    data.map(board_element => {
                                        boards.push(board_element.name)
                                        this.setState({ boards: boards })
                                    })
                                })*/
                            }
                        })
                    else
                        
                        getFeatures({}, (data) => {
                            console.log(data)
                            var features = [];
                            data.map(e => features.push(e.name))
                            this.setState({ features: features })
                        });

                        getBoards({}, (data) => {
                        console.log(data);
                        console.log("^^^^^^^^^^^^^^^^^------------");
                        var boards = [];
                        data.map(e => boards.push(e.board))
                        this.setState({ boards: boards })
                        }); 
                }

                if (this.state.POST) {
                    getResources({ type: 'brand' }, (data) => {
                        console.log("BRANDLISTTTTTTTTTTTTT")
                        console.log(data)
                        var brands = [];
                        data.map(brand_element => {
                            brands.push(brand_element.name)
                            this.setState({ brands: brands })
                        })
                    })

                    getResources({ type: 'vendor' }, (data) => {
                        //console.log(JSON.stringify(data));
                        //console.log(JSON.stringify(result[0].vendor));                        
                        //console.log("VENDOR TYPE LIST--->")
                        var vendors = [];
                        //if(this.state.policy === 'admin')
                            {
                                data.map(vendor_element => {
                                    vendors.push(vendor_element.name)
                                    this.setState({ vendors: vendors })
                                })
                            }
                        
                    }) 
                    
                    
                    getResources({ type: 'factory' }, (data) => {
                        var factorys = [];
                        //if(this.state.policy === 'admin')
                            {
                                data.map(factory_element => {
                                    factorys.push(factory_element.name)
                                    this.setState({ factorys: factorys })
                                })
                            }
                        
                    }) 

                    /*
                    getResources({ type: 'mboard' }, (data) => {
                        console.log(data)
                        var boards = [];
                        data.map(board_element => {
                            boards.push(board_element.name)
                            this.setState({ boards: boards })
                        })
                    })
                    */
                }
            }
        })
    }

    render() {
        return (

            <React.Fragment >
                <Card fullWidth>
                    <Card.Header>BUNDLE UPLOADER</Card.Header>
                    {this.state.POST ? <Card.Body>
                        <Form
                            scrollToError={30}
                            onSubmit={data => {
                                var request_data = {
                                    emacs: this.state.xlsx_data,
                                    brand: data.brand,
                                    mboard: data.mboard,
                                    model: data.model,
                                    features: data.features,
                                    factory: data.factory,
                                    vendor: data.vendor,
                                    state: '1'
                                }
                                console.log(request_data)
                                csvbundleUploader(request_data, status => {
                                    if (status) {
                                        Message.success('EMACs activated successfully!', 3, { position: 'bottom-right' });
                                    } else {
                                        Message.error('Please check your license balance or contact Cloud TV support.', 3, { position: 'bottom-right' });
                                    }

                                })
                            }}>
                            <Alert type="info" icon>
                                Please select all fields here &amp; add csv file with proper template
                            </Alert>
                            <Grid gutter={2}>
                                <Grid width={1 / 2}>
                                    <Grid gutter={1}>
                                        <Grid width={1}>
                                            <Form.Item required label="Brand">
                                                <Select
                                                    name="brand"
                                                    data={this.state.brands}
                                                    rules={[rules.required, rules.isExist]}
                                                    //disabled={this.state.isAllEditable || this.state.fields.indexOf('brand') > -1 ? false : true}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    onChange={
                                                        value => {
                                                            getFeatures({ brands: [value] }, (data) => {
                                                                console.log(data)
                                                                var features = [];
                                                                data.map(e => features.push(e.name))
                                                                this.setState({ features: features })
                                                            });
                                                            console.log(value);
                                                            getBoards({ brand: [value] }, (data) => {
                                                                console.log('DISPLAY ADDED' + data)
                                                                var boards = [];
                                                                var listboards = [];
                                                                data.map(e => boards.push(e.board + '@' + e.keymd5))
                                                                //this.setState({ boards: boards })
                                                                //var myarray = [];
                                                                //console.log('start->' + boards[i]);
                                                                
                                                                var boardlength;
                                                                console.log("LENGTH-->" + boards.length);
                                                                if (boards.length > 0) {
                                                                    if (boards.indexOf(',') != -1) {
                                                                        console.log("PRESNECE OF COMMA");
                                                                        boardlength = str.split(',');
                                                                    }

                                                                    if (boardlength <= 1) {
                                                                        var last = boards[0];
                                                                        var keymd5get = last.split('@');
                                                                        //listboards.push(keymd5get[0] + '-' + keymd5get[1])
                                                                        let text = keymd5get[1];
                                                                        let changevalue = text.startsWith("FD889");
                                                                        if (changevalue) {
                                                                            listboards.push('(CLOUDTV)' + ' ' + keymd5get[0])
                                                                        } else {
                                                                            listboards.push('(OEM)' + ' ' + keymd5get[0])
                                                                        }
                                                                    }
                                                                    else {
                                                                        var str = boards;
                                                                        console.log(str);
                                                                        //var myarray = str.split(',');
                                                                        for (var j = 0; j < boards.length; j++) {
                                                                            //console.log('boards-->' + boards[j]);
                                                                            var str = boards[j];
                                                                            var myarray = str.split(',');
                                                                            console.log('values-->' + myarray)
                                                                            console.log('length-->' + myarray.length)
                                                                            console.log('\n\n')
                                                                            if (myarray.length <= 1) {
                                                                                var last = myarray[0];
                                                                                var keymd5get = last.split('@');
                                                                                //listboards.push(keymd5get[0] + '-' + keymd5get[1])
                                                                                //console.log("ONE ARRAY---->" + listboards)
                                                                                let text = keymd5get[1];
                                                                                let changevalue = text.startsWith("FD889");
                                                                                if (changevalue) {
                                                                                    listboards.push('(CW)' + ' ' + keymd5get[0])
                                                                                } else {
                                                                                    listboards.push('(NONCW)' + ' ' + keymd5get[0])
                                                                                }
                                                                            } else {
                                                                                var last = myarray.slice(-1)[0];
                                                                                var keymd5get = last.split('@');
                                                                                //console.log("last STRING ARRAy" + keymd5get)
                                                                                var newarray = myarray.pop();
                                                                                myarray.push(keymd5get[0])
                                                                                let text = keymd5get[1];
                                                                                let changevalue = text.startsWith("FD889");
                                                                                let signaturetext;
                                                                                if (changevalue) {
                                                                                    signaturetext = '(CW)' + ' ';
                                                                                } else {
                                                                                    signaturetext = '(NONCW)' + ' ';
                                                                                }
                                                                                myarray = myarray.map(c => signaturetext + c);
                                                                                //console.log("final ARRAY%%%%%%%>" + myarray)
                                                                                listboards.push.apply(listboards, myarray);
                                                                                //console.log(" MULTI ARRAY ---->" + listboards)
                                                                            }
                                                                        }

                                                                    }
                                                                } else {
                                                                    listboards.push()
                                                                }
                                                                let uniqueChars = [];
                                                                listboards.forEach((c) => {
                                                                    if (!uniqueChars.includes(c)) {
                                                                        uniqueChars.push(c);
                                                                    }
                                                                });
                                                                uniqueChars.sort();
                                                                this.setState({ boards: uniqueChars })
                                                            });
                                                            this.setState({ brand: value })
                                                        }}
                                                    single />
                                            </Form.Item>
                                        </Grid>
                                        <Grid width={1}>
                                            <Form.Item required label="Board">
                                                <Select
                                                    name="mboard"
                                                    data={this.state.boards}
                                                    rules={[rules.required, rules.isExist]}
                                                    onChange={mboard => this.setState({ mboard: mboard })}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    single />
                                            </Form.Item>
                                        </Grid>

                                        <Grid width={1}>
                                            <Form.Item label="Features">
                                                <Select
                                                    name="features"
                                                    data={this.state.features}
                                                    clearable
                                                    keygen
                                                    onChange={feature => this.setState({ feature: feature })}
                                                    onFilter={text => d =>
                                                        d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                    multiple />
                                            </Form.Item>
                                        </Grid>
                                        <Grid width={1}>
                                            <Form.Item
                                                required label="Model">
                                                <Input
                                                    name="model"
                                                    rules={[rules.required]}
                                                />
                                            </Form.Item>
                                        </Grid>
                                        <Grid width={1}>
                                            <Form.Item
                                                required label="Factory">
                                                {/* <Input
                                                    name="factory"
                                                    rules={[rules.required]}
                                                />
                                                */}
                                                <Select
                                                    name="factory"
                                                    data={this.state.factorys}
                                                    rules={[rules.required, rules.isExist]}
                                                    onChange={factory => this.setState({ factory: factory })}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    single />
                                            </Form.Item>
                                        </Grid>
                                        <Grid width={1}>
                                        <Form.Item required label="Vendor">
                                            {
                                                this.state.policy === 'admin' ? 
                                                <Select
                                                    name="vendor"
                                                    data={this.state.vendors}
                                                    rules={[rules.required, rules.isExist]}
                                                    onChange={vendor => this.setState({ vendor: vendor })}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    single />
                                                : 
                                                /*<Input
                                                    name="vendor"
                                                    rules={[rules.required]}
                                                    readOnly={true}
                                                    value={this.state.vendors[0]}
                                                    defaultValue={this.state.vendors[0]}
                                                />*/
                                                <Select
                                                    name="vendor"
                                                    data={this.state.vendors}
                                                    //defaultValue={{ label:this.state.vendors.name, value:this.state.vendors.name }}
                                                    rules={[rules.required, rules.isExist]}
                                                    onChange={vendor => this.setState({ vendor: vendor })}
                                                    clearable
                                                    keygen
                                                    onFilter={text => d => d.indexOf(text) >= 0}
                                                    single />
                                            } 
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid width={1 /2}>
                                    <Upload
                                        action={UPLOAD_URL}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        name="file"
                                        // headers={{ Authorization: 'Bearer ' + this.state.token }}
                                        params={{ brand: this.state.brands, board: this.state.boards, selected_brand: this.state.brand, selected_board: this.state.mboard, selected_features: this.state.feature,selected_vendor: this.state.vendor }}
                                        htmlName={"file"}
									limit={1}
									renderResult={(f) => f}
									onSuccess={(res, file, data) => {
										res = JSON.parse(res);
										console.log(res);
										if (res.errorCode) {
											if (res.errorCode >= 105) {
												this.setState({ data: res.data });
												this.setState({
													table: (
														<MaterialTable
															title={
																res.errorMessage +
																"!! Fix entries below to continue"
															}
															columns={this.state.columns}
															data={this.state.data}
															onRowClick={(
																event,
																rowData,
																toggleDetailPanel
															) => {
																console.log(rowData);
															}}
															options={{
																pageSize:
																	res.data.length < 100 ? res.data.length : 100,
																pageSizeOptions: [20, 50, 100, 200, 300, 500],
																padding: "dense",
																grouping: true,
																filtering: true,
																exportAllData: true,
																exportButton: true,
																exportDelimiter: ",",
																exportFileName: "LMS_ERROR_DATA",
															}}
														/>
													),
												});
												return new Error();
											} else {
												this.setState({ table: "" });
												return new Error(res.errorMessage);
											}
										} else {
											this.setState({ xlsx_data: res });
											this.setState({ data: [] });
											this.setState({ table: "" });
											return "Data parsed, Total entries: " + res.length;
										}
									}}
									validator={{
										ext: (ext) =>
											["xls", "xlsx"].includes(ext)
												? undefined
												: new Error("File extension must be xls or xlsx"),
									}}
                                        >
                                        <Upload.Dragger>
                                            <i className="material-icons">cloud_upload</i>
                                            <br />
                                            Click or Drag application to upload
                                            <br />
                                            Upload Excel file
                                        </Upload.Dragger>
                                    </Upload>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.table}
                    </Card.Body> : ''}
                    <Card.Footer>
                        <div style={{ textAlign: 'left' }}>
                            Download XLSX file template <a href="/template_csv.xlsx">here</a>
                        </div>
                        {this.state.POST ? <Card.Submit
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 100,
                            }}
                        >Submit</Card.Submit> : ''}
                    </Card.Footer>
                </Card>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(withCookies(CsvbundleUploader));
