import superagent from 'superagent';

const URL = '/lm/v1/features';

export const create = (body, callback) => {
    superagent.post(URL)
        .send(body)
        .end((err, response) => {
            console.log(response)
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};

export const get = (query, callback) => {
    superagent.get(URL)
        .query(query)
        .end((err, response) => {
            if (err) {
                callback([]);
                console.log('Request failed')
            } else {
                console.log(response);
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
        });
};

export const update = (oldValue, newValue, callback) => {
    newValue.old_name = oldValue.name;
    superagent.put(URL)
        .send(newValue)
        .end((err, response) => {
            console.log(response);
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        });
};

export const delet = (body, callback) => {
    superagent.delete(URL)
        .send({
            name: body.name,
            brands: body.brands
        })
        .end((err, response) => {
            console.log(JSON.stringify(response))
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};
