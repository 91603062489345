import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withCookies } from "react-cookie";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { login } from './network/Login'
import { Message } from 'shineout'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LoadingDialog from "../commons/LoadingDialog";

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDone: false,
            name: '',
            password: '',
            loadingMessage: "Verifying credentials, Please wait",
            status: 0
        }
        this.handleSubmit = this.handleSubmit.bind()
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.setState({ isDone: false });

        login({
            name: this.state.name,
            password: this.state.password,
        }, result => {
            if (result != null) {
                console.log(result);
                this.setState({ status: 200 });
                this.setState({ policy: result.policy });
                this.setState({ isDone: true });

                Message.success('Login done', 3, { position: 'bottom-right' });

                this.props.onLogin({
                    policy: this.state.policy,
                });

            } else {
                this.setState({ status: 400 });
                this.setState({ isLoading: false });
                Message.error('Login failed', 3, { position: 'bottom-right' });
                this.setState({ isDone: true });
            }
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <LoadingDialog
                    open={this.state.isLoading}
                    status={this.state.isDone}
                    message={this.state.loadingMessage} />
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="username"
                            onChange={event => this.setState({ name: event.target.value })}
                            id="username"
                            label="username"
                            autoComplete="word"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={event => this.setState({ password: event.target.value })}
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className={classes.submit}
                        >Login</Button>
                    </form>
                </div>
            </Container>

        )
    }
}
export function logout(props) {
    window.location.reload();
    props.cookies.remove("username", "/");
    props.cookies.remove("policy", "/");
    props.cookies.remove("version", "/");
}
export default withStyles(styles)(withCookies(Login));
