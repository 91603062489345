import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { generate } from "../network/Reports";
import { routePermissions } from '../../auth/network/Login'
import { withCookies } from "react-cookie";
import { format, addDays, subDays } from 'date-fns'
import { CsvBuilder } from 'filefy';
import { Rule, Card, Message, Input, Form, Select, Grid, DatePicker } from 'shineout'

const service = 'reports';
const path = '';
const today = new Date()
const formatStart = 'yyyy-MM-dd 00:00:00'
const formatEnd = 'yyyy-MM-dd 23:59:59'
const rules = Rule();
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            column_templates: {
                emac: { title: 'EMAC', field: 'emac' },
                model: { title: 'Model', field: 'model' },
                brand: { title: 'Brand', field: 'brand' },
                mboard: {
                    title: 'MBoard', field: 'mboard', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                panel: {
                    title: 'Panel', field: 'panel', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                lversion: {
                    title: 'L-Version', field: 'lversion', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                serialno: {
                    title: 'Serial No', field: 'serialno', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                cotaversion: { title: 'COTA', field: 'cotaversion' },
                fotaversion: { title: 'FOTA', field: 'fotaversion' },
                description: {
                    title: 'Description', field: 'description', cellStyle: {
                        maxWidth: 60,
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                accept_version: { title: 'Accept-version', field: 'accept_version' },
                lastacessIP: { title: 'Last IP', field: 'lastacessIP' },
                state: { title: 'State', field: 'state' },
            },
            columns: [],
            data: [],
            policies: JSON.parse(this.props.cookies.cookies.policy),
            fields: [],
            POST_DATA: [],
            rule_views: [],
            field: [],
            rule_data: {},
            filter_data: [],
            date_range: []
        };
    }

    componentWillMount() {
        console.log({ service: service, path: path })
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.POST) {
                    var filter_fields = this.state.POST_DATA.find(e => e.key === 'filter_fields');
                    console.log(filter_fields)
                    if (!filter_fields) {
                        this.setState({ fields: null });
                        this.setState({ columns: null });
                    } else {
                        var fields = [];
                        var field_list = [];
                        if (filter_fields.value.indexOf('*') > -1) {
                            Object.keys(this.state.column_templates).map(k => fields.push(this.state.column_templates[k]))
                            field_list = Object.keys(this.state.column_templates);
                        } else {
                            filter_fields.value.map(e => fields.push(this.state.column_templates[e]))
                            field_list = filter_fields.value;
                        }
                        console.log(fields)
                        var rule_data = {};
                        field_list.map(field => {
                            rule_data[field] = {
                                field: field,
                                operator: '',
                                values: []
                            }
                        });
                        this.setState({ fields: field_list });
                        this.setState({ columns: fields });
                        this.setState({ rule_data: rule_data })
                    }
                } else {
                    this.setState({ fields: null });
                    this.setState({ columns: null });
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Card fullWidth>
                    <Card.Body>
                        <h2>Report Generation</h2>
                        <Form
                            onSubmit={value => {
                                console.log(value)
                                var data = [];
                                value.field.map(field => data.push(this.state.rule_data[field]))
                                console.log(data)
                                this.setState({ filter_data: data })
                                var date_range = value.date_range ? value.date_range : ["", ""];
                                if (date_range[0] === '' || date_range[1] === '') {
                                    date_range = undefined;
                                }
                                this.setState({ date_range: date_range })
                                generate({ filters: data, date_range: date_range }, result => {
                                    console.log(result)
                                    if (result == null) {
                                        Message.error('No Data Available', 3, { position: 'bottom-right' });
                                    } else {
                                        this.setState({ data: result })
                                        Message.success('Data Found', 3, { position: 'bottom-right' });
                                    }
                                })
                            }}
                        >
                            <Form.Item required label="Report Name">
                                <Input
                                    name="report_name"
                                    fullWidth
                                    onChange={value => this.setState({ report_name: value })}
                                    value={this.state.report_name}
                                    rules={[rules.required, rules.isExist]}
                                />
                            </Form.Item>
                            <Form.Item required label="Fields">
                                <Select
                                    name="field"
                                    fullWidth
                                    style={{ marginRight: 8 }}

                                    onChange={value => {
                                        getRuleView(this, value, views => {
                                            this.setState({ rule_views: views })
                                        })
                                    }}
                                    data={this.state.fields}
                                    clearable
                                    keygen
                                    onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                    rules={[rules.required, rules.isExist]}
                                    multiple />
                            </Form.Item>
                            <Form.Item label="Date between">
                                <DatePicker
                                    name="date_range"
                                    range
                                    onChange={d => console.log(d)}
                                    type="datetime"
                                    inputable
                                    quickSelect={[
                                        {
                                            name: 'Today',
                                            value: [Date.now(), Date.now()],
                                        },
                                        {
                                            name: 'Next week',
                                            value: [format(today, formatStart), format(addDays(today, 7), formatEnd)],
                                        },
                                        {
                                            name: 'Last week',
                                            value: [format(subDays(today, 7), formatStart), format(today, formatEnd)],
                                        },
                                        {
                                            name: 'Next month',
                                            value: [format(today, formatStart), format(addDays(today, 30), formatEnd)],
                                        },
                                        {
                                            name: 'Last month',
                                            value: [format(subDays(today, 30), formatStart), format(today, formatEnd)],
                                        },
                                    ]}
                                    style={{ marginTop: '12px' }}
                                />
                            </Form.Item>
                            {this.state.rule_views}
                        </Form>

                        <Card.Submit
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                width: '100%',
                            }}
                        >Search</Card.Submit>

                        <MaterialTable
                            title={'Report'}
                            columns={this.state.columns}
                            data={this.state.data}
                            options={{
                                grouping: true,
                                filtering: true,
                                padding: 'dense',
                                pageSize: 20,
						        pageSizeOptions: [20, 50, 100, 150, 500],
                                exportAllData: true,
                                exportButton: true,
                                exportDelimiter: ',',
                                exportCsv: (columns, renderData) => {
                                    columns = columns.filter(columnDef => {
                                        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
                                    })
                                    const data = [];
                                    renderData.map(rowData => {
                                        var temp = [];
                                        columns.map(columnDef => {
                                            temp.push(rowData[columnDef.field]);
                                        })
                                        data.push(temp)
                                    });
                                    data.push([])
                                    data.push(['Date Range', this.state.date_range]);
                                    data.push(['FILTERS']);
                                    this.state.filter_data.map(filter => {
                                        data.push(['', filter.field.toUpperCase(), filter.operator === 'IN' ? 'INCLUDING' : 'EXCLUDING', filter.values]);
                                    })
                                    data.push(['TOTAL: ' + renderData.length]);
                                    new CsvBuilder(this.state.report_name + '.csv')
                                        .setDelimeter(this.props.exportDelimiter)
                                        .setColumns(columns.map(columnDef => columnDef.title))
                                        .addRows(data)
                                        .exportFile();
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

function getRuleView(ref, data, callback) {
    const rule_views = [];
    console.log(data)
    data.map(field => {
        rule_views.push(
            <Card>
                <Card.Body>
                    <Form
                        value={ref.state.rule_data[field]}
                        onChange={value => console.log(value)}
                    >
                        <h3>{field}</h3>
                        <Grid gutter={1}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 12
                            }}>
                            <Grid width={0.5}>
                                <Form.Item required label="Operator">
                                    <Select
                                        name="operator"
                                        fullWidth
                                        style={{ marginRight: 8 }}
                                        data={["IN", "NIN"]}
                                        onChange={value => {
                                            ref.setState(oldState => {
                                                console.log(field)
                                                console.log(oldState.rule_data)
                                                oldState.rule_data[field].operator = value
                                                return { rule_data: oldState.rule_data }
                                            })
                                        }}
                                        clearable
                                        keygen
                                        single
                                        rules={[rules.required, rules.isExist]}
                                    />
                                </Form.Item>
                            </Grid>
                            <Grid width={0.5}>
                                <Form.Item required label="Values">
                                    <Select
                                        name="values"
                                        fullWidth
                                        style={{ marginRight: 8 }}
                                        // separator=','
                                        onChange={value => {
                                            ref.setState(oldState => {
                                                oldState.rule_data[field].values = value
                                                return { rule_data: oldState.rule_data }
                                            })
                                        }}
                                        onCreate
                                        clearable
                                        keygen
                                        onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                        multiple
                                        rules={[rules.required, rules.isExist]}
                                    />
                                </Form.Item>
                            </Grid>
                        </Grid>

                    </Form>
                </Card.Body>
            </Card >
        )
    })
    callback(rule_views)
}

export default withStyles(styles)(withCookies(Reports));
