import React from 'react';
import '../../../App.css';
import "../../../Shineout.css";
import { create, update, get as getDetails } from "../network/Authwalls";
import { get } from "../network/Resources";
import { get as getFeatures } from "../network/Features";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Card, Message, Input, Rule, Form, Select, Grid, Switch } from 'shineout'
import Textarea from 'shineout/lib/Textarea';

const service = 'authwalls';
const path = '';
const rules = Rule();
class TVDetails extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history.location.state.data)
        this.state = {
            policies: JSON.parse(this.props.cookies.cookies.policy),
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            POST_DATA: [],
            GET_DATA: [],
            PUT_DATA: [],
            DELETE_DATA: [],
            mboards: [],
            brands: [],
            factorys: [],
            vendors: [],
            creatable_fields: [],
            editable_fields: [],
            fields: [],
            visible_fields: [],
            isAllVisible: false,
            isAllEditable: false,
            tv_data: this.props.history.location.state.data ? this.props.history.location.state.data : undefined,
        }
    };

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                    var visible_fields = this.state.GET_DATA.find(e => e.key == 'visible_fields');
                    console.log(visible_fields)
                    if (visible_fields) {
                        if (visible_fields.value.indexOf('*') > -1) {
                            this.setState({ isAllVisible: true });
                        }
                        this.setState({ visible_fields: visible_fields.value });
                    }
                    if (this.state.tv_data)
                        getDetails({ emac: this.state.tv_data.emac }, result => {
                            console.log(result)
                            if (result.length == 0)
                                Message.error('EMAC not resolved', 3, { position: 'bottom-right' });
                            else {
                                if (result[0].state == '0') {
                                    result[0].state_text = 'Deactivated'
                                } else if (result[0].state == '1') {
                                    result[0].state_text = 'Activated'
                                } else {
                                    result[0].state_text = 'Blocked'
                                }
                                this.setState({ tv_data: result[0] })
                                getFeatures({ brands: [result[0].brand] }, (data) => {
                                    console.log(data)
                                    var features = [];
                                    data.map(e => features.push(e.name))
                                    this.setState({ features: features })
                                });
                            }
                        })
                    else
                        getFeatures({}, (data) => {
                            console.log(data)
                            var features = [];
                            data.map(e => features.push(e.name))
                            this.setState({ features: features })
                        });

                        get({ type: 'attribute' }, (data) => {
                            console.log(data)
                            var attributes = [];
                            data.map(e => attributes.push(e.name))
                            this.setState({ attributes: attributes })
                        });

                }

                if (this.state.POST) {
                    var creatable_fields = this.state.POST_DATA.find(e => e.key == 'creatable_fields');
                    console.log(creatable_fields)
                    if (creatable_fields) {
                        if (creatable_fields.value.indexOf('*') > -1) {
                            this.setState({ isAllEditable: true });
                        }
                        this.setState({ creatable_fields: creatable_fields.value });
                    }
                }

                if (this.state.PUT) {
                    var editable_fields = this.state.PUT_DATA.find(e => e.key == 'editable_fields');
                    console.log(editable_fields)
                    if (editable_fields) {
                        if (editable_fields.value.indexOf('*') > -1) {
                            this.setState({ isAllEditable: true });
                        }
                        this.setState({ editable_fields: editable_fields.value });
                    }
                }

                if (this.state.tv_data) {
                    this.setState({ fields: this.state.editable_fields })
                } else {
                    this.setState({ fields: this.state.creatable_fields })
                }
            }
        })

        get({ type: 'brand' }, (data) => {
            console.log(data)
            var brands = [];
            data.map(e => brands.push(e.name))
            this.setState({ brands: brands })
        });

        get({ type: 'mboard' }, (data) => {
            console.log(data)
            var mboards = [];
            data.map(e => mboards.push(e.name))
            this.setState({ mboards: mboards })
        });
        get({ type: 'vendor' }, (data) => {
            console.log(data)
            var vendors = [];
            data.map(e => vendors.push(e.name))
            this.setState({ vendors: vendors })
        });

        get({ type: 'factory' }, (data) => {
            console.log(data)
            var factorys = [];
            data.map(e => factorys.push(e.name))
            this.setState({ factorys: factorys })
        });
    }

    render() {
        return (
            <React.Fragment>
                <Card >
                    <Card.Header>
                        <h2>Device Details</h2>
                    </Card.Header>
                    <Card.Body style={{
                        'text-align': 'left',
                    }}>
                        <Form
                            disabled={!this.state.PUT}
                            value={this.state.tv_data}
                            onSubmit={value => {
                                console.log(this.state.tv_data)
                                console.log(value)
                                switch (value.state_text) {
                                    case 'Deactivated':
                                        value.state = 0
                                        break;
                                    case 'Activated':
                                        value.state = 1
                                        break;
                                    case 'Blocked':
                                        value.state = 2
                                        break;
                                }
                                if (this.state.tv_data == undefined) {
                                    this.state.POST ? create(value, result => {
                                        if (result) {
                                            this.setState({ tv_data: value })
                                            Message.success('Device created', 3, { position: 'bottom-right' });
                                        }
                                        else {
                                            Message.error('Failed to create device. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                } else {
                                    this.state.PUT ? update(this.state.tv_data, value, result => {
                                        if (result) {
                                            this.setState({ tv_data: value })
                                            Message.success('Device updated', 3, { position: 'bottom-right' });
                                        }
                                        else {
                                            Message.error('Failed to update device. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                }
                            }}>
                            <Grid gutter={2}>
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('emac') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item
                                            required label="EMAC">
                                            <Input
                                                rules={[rules.required]}
                                                name="emac"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('emac') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('brand') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item required label="Brand">
                                            <Select
                                                name="brand"
                                                data={this.state.brands}
                                                rules={[rules.required, rules.isExist]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('brand') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d => d.indexOf(text) >= 0}
                                                onChange={value => {
                                                    getFeatures({ brands: [value] }, (data) => {
                                                        console.log(data)
                                                        var features = [];
                                                        data.map(e => features.push(e.name))
                                                        this.setState({ features: features })
                                                    });
                                                }}
                                                single />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('model') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item required label="Model">
                                            <Input
                                                name="model"
                                                rules={[rules.required]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('model') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('mboard') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item required label="M-Board">
                                            <Select
                                                name="mboard"
                                                data={this.state.mboards}
                                                rules={[rules.required, rules.isExist]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('mboard') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d => d.indexOf(text) >= 0}
                                                single />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('panel') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Panel">
                                            <Input
                                                name="panel"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('panel') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('lversion') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="L-Version">
                                            <Input
                                                name="lversion"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('lversion') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('features') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Features">
                                            <Select
                                                name="features"
                                                data={this.state.features}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('features') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d =>
                                                    d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                multiple />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('serialno') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Serial no.">
                                            <Input
                                                name="serialno"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('serialno') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('cotaversion') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="COTA Version">
                                            <Input
                                                name="cotaversion"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('cotaversion') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('fotaversion') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="FOTA Version">
                                            <Input
                                                name="fotaversion"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('fotaversion') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('accept_version') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Accept version">
                                            <Input
                                                name="accept_version"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('accept_version') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('lastacessIP') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Last Access IP">
                                            <Input
                                                name="lastacessIP"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('lastacessIP') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {/*    
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('factory') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item
                                            required label="Factory Name">
                                            <Input
                                                required
                                                name="factory"
                                                rules={[rules.required]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('factory') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}                                
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('vendor') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item
                                            required label="Vendor Name">
                                            <Input
                                                required
                                                name="vendor"
                                                rules={[rules.required]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('vendor') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                */}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('factory') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item required label="FACTORY">
                                            <Select
                                                name="factory"
                                                data={this.state.factorys}
                                                rules={[rules.required, rules.isExist]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('factory') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d => d.indexOf(text) >= 0}
                                                single />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('vendor') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item required label="VENDOR">
                                            <Select
                                                name="vendor"
                                                data={this.state.vendors}
                                                rules={[rules.required, rules.isExist]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('vendor') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d => d.indexOf(text) >= 0}
                                                single />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('keymd5') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="KeyMD5">
                                            <Input
                                                name="keymd5"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('keymd5') > -1 ? false : true}
                                                autosize />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('default') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="isDefaultKey">
                                            <Switch
                                                name="default"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('default') > -1 ? false : true}
                                                autosize />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('description') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Description">
                                            <Textarea
                                                name="description"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('description') > -1 ? false : true}
                                                autosize />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('wmac') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="WMAC">
                                            <Input
                                                name="wmac"
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('wmac') > -1 ? false : true}
                                            />
                                        </Form.Item>
                                    </Grid> : ""}
                                {this.state.isAllVisible || this.state.visible_fields.indexOf('state') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item required label="State">
                                            <Select
                                                name="state_text"
                                                data={['Activated', 'Blocked', 'Deactivated']}
                                                rules={[rules.required, rules.isExist]}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('state') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d => d.indexOf(text) >= 0}
                                                single />
                                        </Form.Item>
                                    </Grid> : ""}
                            </Grid>
                            {
                                this.state.isAllVisible || this.state.visible_fields.indexOf('attributes') > -1 ?
                                    <Grid width={0.5}>
                                        <Form.Item label="Attributes">
                                            <Select
                                                name="attributes"
                                                data={this.state.attributes}
                                                disabled={this.state.isAllEditable || this.state.fields.indexOf('attributes') > -1 ? false : true}
                                                clearable
                                                keygen
                                                onFilter={text => d =>
                                                    d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                multiple />
                                        </Form.Item>
                                    </Grid> : ""
                                    
                                    }
                        </Form>
                        {this.state.POST || this.state.PUT ? <Card.Submit
                            style={{
                                width: '100%',
                            }}
                        >Save</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withCookies(TVDetails);