import superagent from "superagent";
import { logout } from "../Login";

const URL = "/lm/v1/login";

export const login = (credentials, callback) => {
	superagent
		.post(URL)
		.send(credentials)
		.end((err, response) => {
			console.log(response);
			if (err) {
				callback(null);
				console.log("Request failed");
			} else {
				if (response.status === 200) {
					response.body.version = response.headers.version;
					callback(response.body);
				} else {
					callback(null);
				}
			}
		});
};

export const routePermissions = (props, data, callback) => {
	superagent
		.post(URL + "/p")
		.send(data)
		.end((err, response) => {
			if (err) {
				callback(null);
				console.log("ERROR: " + err);
			}
			if (response.status === 200) {
				callback(response.body);
			} else if (response.status === 401) {
				logout(props)
			} else {
				callback(null);
			}
		});
};

export const logoutRoute = (callback) => {
	superagent
		.post(URL + '/e')
		.end((err, response) => {
			console.log(err)
			console.log(response)
			callback(response.status == 200 ? true : false);
		});
};

