import React from 'react';
import { create, update } from "../network/Features";
import { get } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Card, Message, Input, Rule, Form, Select, Grid } from 'shineout'
import ReactJson from "react-json-view";

const service = 'authwalls';
const path = '';
const rules = Rule();
class Feature extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history.location.state.data)
        this.state = {
            policies: JSON.parse(this.props.cookies.cookies.policy),
            POST: false,
            GET: false,
            PUT: false,
            POST_DATA: [],
            GET_DATA: [],
            PUT_DATA: [],
            brands: [],
	        features :[],
            feature_data: this.props.history.location.state.data ? this.props.history.location.state.data : undefined,
        }
    };

    componentWillMount() {
        if (this.state.feature_data) {
            this.setState({ config: this.props.history.location.state.data.config })

        }

	 get({ type: 'feature' }, (data) => {        
            var features = [];
            data.map(e => features.push(e.name))
            this.setState({ features: features })
        });

        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                    var brands = this.state.GET_DATA.find(e => e.key == 'brand');
                    console.log(brands)
                    if (brands) {
                        if (brands.value.indexOf('*') > -1) {
                            get({ type: 'brand' }, (data) => {
                                console.log(data)
                                var brands = [];
                                data.map(e => brands.push(e.name))
                                this.setState({ brands: brands })
                            });
                        } else {
                            this.setState({ brands: brands.value });
                        }
                    }
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Card >
                    <Card.Header>
                        <h2>Feature Details</h2>
                    </Card.Header>
                    <Card.Body style={{
                        'text-align': 'left',
                    }}>
                        <Form
                            disabled={!this.state.PUT}
                            value={this.state.feature_data}
                            onSubmit={value => {
                                console.log(this.state.feature_data)
                                console.log(value)
                                value.config = this.state.config
                                if (this.state.feature_data == undefined) {
                                    this.state.POST ? create(value, result => {
                                        if (result) {
                                            this.setState({ feature_data: value })
                                            Message.success('Feature created', 3, { position: 'bottom-right' });
                                        }
                                        else {
                                            Message.error('Failed to create feature. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                } else {
                                    this.state.PUT ? update(this.state.feature_data, value, result => {
                                        if (result) {
                                            this.setState({ feature_data: value })
                                            Message.success('Feature updated', 3, { position: 'bottom-right' });
                                        }
                                        else {
                                            Message.error('Failed to update feature. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                }
                            }}>
                            <Grid gutter={2}>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Name">
                                        <Input
                                            name="name"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Feature">
                                        <Select
                                            name="feature"
                                            data={this.state.features}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            onCreate
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1}>
                                    <Form.Item required label="Brands">
                                        <Select
                                            name="brands"
                                            data={this.state.brands}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(('' + text).toUpperCase()) >= 0}
                                            multiple />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1}>
                                    <Form.Item required label="Config">
                                        <ReactJson
                                            autosize
                                            fullWidth
                                            src={this.state.config}
                                            onEdit={edit => {
                                                this.setState({ config: edit.updated_src })
                                                console.log(edit)
                                            }}
                                            onAdd={add => {
                                                this.setState({ config: add.updated_src })
                                                console.log(add)
                                            }}
                                            onDelete={del => {
                                                this.setState({ config: del.updated_src })
                                                console.log(del)
                                            }}
                                        />
                                    </Form.Item>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.POST || this.state.PUT ? <Card.Submit
                            style={{
                                width: '100%',
                            }}
                        >Save</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withCookies(Feature);
