import React from "react";
import "../../../App.css";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import { create, update, get, delet } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Message, Upload } from "shineout";

const service = "resources";
const path = "";
const UPLOAD_URL = "/lm/v1/resources/u";
const styles = theme => ({
	theme: theme,
	root: {
		display: "wrap"
	},
	container: {
		display: "block",
		flexWrap: "wrap"
	},
	fab: {
		margin: theme.spacing(1)
	},
	box: {
		component: "div",
		display: "inline",
		flexWrap: "wrap"
	},
	card: {
		padding: theme.spacing(1),
		margin: theme.spacing(2)
	},
	textField: {
		margin: 8,
		padding: theme.spacing(1),
		width: 200
	},
	media: {
		paddingTop: "56.25%"
	}
});

class BoardList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{ title: "Name", field: "name" },
				{
					title: "Image",
					field: "extras.board",
					editComponent: props => (
						<Upload
							action={UPLOAD_URL}
							accept="image/*"
							name="board"
							params={{
								name: props.rowData.name
							}}
							htmlName={"board"}
							limit={1}
							renderResult={f => f}
							onSuccess={(res, file, data) => {
								res = JSON.parse(res);
								console.log(res);
								if (res.errorMessage) {
									return "ERROR: " + res.errorMessage;
								}
								props.onChange(res.url);
								return res.url;
							}}
							validator={{
								ext: ext =>
									["webp", "png", "jpg", "jpeg"].includes(ext)
										? undefined
										: new Error("File extension must be webp")
							}}
						>
							<Upload.Dragger>
								<i className="material-icons">cloud_upload</i>
								<br />
								Click or Drag application to upload
								<br />
								Upload Excel file
							</Upload.Dragger>
						</Upload>
					)
				}
			],
			data: [],
			policy: JSON.parse(this.props.cookies.cookies.policy),
			isLoading: true,
			POST: false,
			GET: false,
			PUT: false,
			DELETE: false
		};
	}

	componentDidMount() {
		routePermissions(
			this.props,
			{ service: service, path: path },
			result => {
				if (result === null) {
					this.setState({ isError: true });
				} else {
					result.map(method => {
						this.setState({ [method.name]: true });
						this.setState({ [method.name + "_DATA"]: method.resources });
					});
					if (this.state.GET) {
						get({ type: "mboard" }, data => {
							console.log(data);
							this.setState({ data: data });
							this.setState({ isLoading: false });
						});
					}
				}
			}
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<MaterialTable
					title={"Boards"}
					columns={this.state.columns}
					data={this.state.data}
					isLoading={this.state.isLoading}
					options={{
						loadingType: "overlay",
						addRowPosition: "first",
						actionsColumnIndex: 100,
						pageSize: 20
					}}
					editable={{
						onRowAdd: this.state.POST
							? newData =>
								new Promise(resolve => {
									console.log(newData);
									newData.type = "mboard";
									create(newData, result => {
										if (result)
											get({ type: "mboard" }, data => {
												this.setState({ data: data });
												Message.success("Board created", 3, {
													position: "bottom-right"
												});
												resolve();
											});
										else {
											Message.error("Failed to create board. Try again", 3, {
												position: "bottom-right"
											});
											resolve();
										}
									});
								})
							: undefined,
						onRowUpdate: this.state.PUT
							? (newData, oldData) =>
								new Promise(resolve => {
									update(oldData, newData, result => {
										if (result)
											get({ type: "mboard" }, data => {
												this.setState({ data: data });
												Message.success("Board updated", 3, {
													position: "bottom-right"
												});
												resolve();
											});
										else {
											Message.error("Failed to delete board. Try again", 3, {
												position: "bottom-right"
											});
											resolve();
										}
									});
								})
							: undefined,
						onRowDelete: this.state.DELETE
							? oldData =>
								new Promise(resolve => {
									delet(oldData.name, result => {
										if (result)
											get({ type: "mboard" }, data => {
												this.setState({ data: data });
												Message.success("Board deleted", 3, {
													position: "bottom-right"
												});
												resolve();
											});
										else {
											Message.error("Failed to delete board. Try again", 3, {
												position: "bottom-right"
											});
											resolve();
										}
									});
								})
							: undefined
					}}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(withCookies(BoardList));
