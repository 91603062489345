import React from 'react';
import { create, update } from "../network/Skintvlaunchers";
import { get } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Card, Message, Input, Rule, Form, Select, Grid } from 'shineout'
import ReactJson from "react-json-view";

const service = 'authwalls';
const path = '';
const rules = Rule();
class Skintvlauncher extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history.location.state.data)
        this.state = {
            policies: JSON.parse(this.props.cookies.cookies.policy),
            POST: false,
            GET: false,
            PUT: false,
            POST_DATA: [],
            GET_DATA: [],
            PUT_DATA: [],
            brand: [],
            vendor: [],
            factory: [],
            board: [],
            skin: [],
            skintvlauncher_data: this.props.history.location.state.data ? this.props.history.location.state.data : undefined,
        }
    };

    componentWillMount() {
        if (this.state.skintvlauncher_data) {
            this.setState({ skin: this.props.history.location.state.data.skin })

        }

        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {

                    var brand = this.state.GET_DATA.find(e => e.key == 'brand');
                    console.log(brand)
                    if (brand) {
                        if (brand.value.indexOf('*') > -1) {
                            get({ type: 'brand' }, (data) => {
                                console.log(data)
                                var brand = [];
                                data.map(e => brand.push(e.name))
                                this.setState({ brand: brand })
                            });
                        } else {
                            this.setState({ brand: brand.value });
                        }
                    }

                    var board = this.state.GET_DATA.find(e => e.key == 'mboard');
                    console.log(board)
                    if (board) {
                        if (board.value.indexOf('*') > -1) {
                            get({ type: 'mboard' }, (data) => {
                                console.log(data)
                                var board = [];
                                data.map(e => board.push(e.name))
                                this.setState({ board: board })
                            });
                        } else {
                            this.setState({ board: board.value });
                        }
                    }
                    var vendor = this.state.GET_DATA.find(e => e.key == 'vendor');
                    console.log(vendor)
                    if (vendor) {
                        if (vendor.value.indexOf('*') > -1) {
                            get({ type: 'vendor' }, (data) => {
                                console.log(data)
                                var vendor = [];
                                data.map(e => vendor.push(e.name))
                                this.setState({ vendor: vendor })
                            });
                        } else {
                            this.setState({ vendor: vendor.value });
                        }
                    }

                    var factory = this.state.GET_DATA.find(e => e.key == 'factory');
                    console.log(factory)
                    if (factory) {
                        if (factory.value.indexOf('*') > -1) {
                            get({ type: 'factory' }, (data) => {
                                console.log(data)
                                var factory = [];
                                data.map(e => factory.push(e.name))
                                this.setState({ factory: factory })
                            });
                        } else {
                            this.setState({ factory: factory.value });
                        }
                    }

                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Card >
                    <Card.Header>
                        <h2>Skintvlauncher Details</h2>
                    </Card.Header>
                    <Card.Body style={{
                        'text-align': 'left',
                    }}>
                        <Form
                            disabled={!this.state.PUT}
                            value={this.state.skintvlauncher_data}
                            onSubmit={value => {
                                console.log(this.state.skintvlauncher_data)
                                console.log(value)
                                value.skin = this.state.skin
                                if (this.state.skintvlauncher_data == undefined) {
                                    this.state.POST ? create(value, result => {
                                        if (result) {
                                            this.setState({ skintvlauncher_data: value })
                                            Message.success('Skintvlauncher created', 3, { position: 'bottom-right' });
                                        }
                                        else {
                                            Message.error('Failed to create skintvlauncher. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                } else {
                                    this.state.PUT ? update(this.state.skintvlauncher_data, value, result => {
                                        if (result) {
                                            this.setState({ skintvlauncher_data: value })
                                            Message.success('Skintvlauncher updated', 3, { position: 'bottom-right' });
                                        }
                                        else {
                                            Message.error('Failed to update skintvlauncher. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                }
                            }}>
                            <Grid gutter={2}>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Name">
                                        <Input
                                            name="name"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Vendor">
                                        <Select
                                            name="vendor"
                                            data={this.state.vendor}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            onCreate
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Brands">
                                        <Select
                                            name="brand"
                                            data={this.state.brand}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(('' + text).toUpperCase()) >= 0}
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Factory">
                                        <Select
                                            name="factory"
                                            data={this.state.factory}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            onCreate
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Keymd5">
                                        <Input
                                            name="keymd5"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Boards">
                                        <Select
                                            name="board"
                                            data={this.state.board}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(('' + text).toUpperCase()) >= 0}
                                            multiple />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1}>
                                    <Form.Item required label="Skin">
                                        <ReactJson
                                            autosize
                                            fullWidth
                                            src={this.state.skin}
                                            onEdit={edit => {
                                                this.setState({ skin: edit.updated_src })
                                                console.log(edit)
                                            }}
                                            onAdd={add => {
                                                this.setState({ skin: add.updated_src })
                                                console.log(add)
                                            }}
                                            onDelete={del => {
                                                this.setState({ skin: del.updated_src })
                                                console.log(del)
                                            }}
                                        />
                                    </Form.Item>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.POST || this.state.PUT ? <Card.Submit
                            style={{
                                width: '100%',
                            }}
                        >Save</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withCookies(Skintvlauncher);
