import React from 'react';
import '../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { create, update, get, delet } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Message } from 'shineout'

const service = 'resources';
const path = '';
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class vendorList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: 'Name', field: 'name' },
            ],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
        };
    }

    componentDidMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                    get({ type: 'vendor' }, (data) => {
                        console.log(data)
                        this.setState({ data: data });
                        this.setState({ isLoading: false })
                    });
                }
            }
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <MaterialTable
                    title={'Vendors'}
                    columns={this.state.columns}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        loadingType: 'overlay',
                        addRowPosition: 'first',
                        actionsColumnIndex: 100,
                        pageSize: 20,
						pageSizeOptions: [20, 50, 100, 150, 500],

                    }}
                    editable={{
                        onRowAdd: this.state.POST ? (newData) =>
                            new Promise(resolve => {
                                newData.type = 'vendor';
                                create(newData, result => {
                                    if (result)
                                        get({ type: 'vendor' }, (data) => {
                                            this.setState({ data: data });
                                            Message.success('vendor created', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to create vendor. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                        onRowUpdate: this.state.PUT ? (newData, oldData) =>
                            new Promise(resolve => {
                                update(oldData, newData, result => {
                                    if (result)
                                        get({ type: 'vendor' }, (data) => {
                                            this.setState({ data: data });
                                            Message.success('vendor updated', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to delete vendor. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                        onRowDelete: this.state.DELETE ? oldData =>
                            new Promise(resolve => {
                                delet(oldData.name, result => {
                                    if (result)
                                        get({ type: 'vendor' }, (data) => {
                                            this.setState({ data: data });
                                            Message.success('vendor deleted', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to delete vendor. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withCookies(vendorList));
