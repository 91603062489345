import superagent from 'superagent';

const URL = '/lm/v1/uiconfigs';

export const create = (uiconfig_body, callback) => {
    superagent.post(URL)
        .send(uiconfig_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(response)
        });
};

export const get = (query, callback) => {
    superagent.get(URL)
        .query(query)
        .end((err, response) => {
            if (err) {
                callback([]);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
            console.log(response);
        });
};

export const update = (oldValue, newValue, callback) => {
    newValue.old_brand = oldValue.brand;
    newValue.old_md5 = oldValue.md5;
    superagent.put(URL)
        .send(newValue)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
            console.log(response);
        });
};

export const delet = (brand, md5, callback) => {
    superagent.delete(URL)
        .send({ brand: brand, md5: md5 })
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(JSON.stringify(response))
        });
};


export const boardsold = (callback) => {
    console.log("000000000000");
    console.log("=============>>>")
    superagent.get(URL + '/boards')
        .end((err, response) => {
            if (err) {
                callback([]);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
            console.log(response);
        });
};

export const boards = (brand, callback) => {
    console.log("ADED=============>>>")
    superagent.post(URL + '/boards')
        .send({ brand: brand })
        .end((err, response) => {
            console.log(response)
            if (err) {
                callback(undefined);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else {
                    callback(undefined)
                }
            }
        });
};