import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { create, update } from "../network/Policies";
import { routePermissions } from "../../auth/network/Login";
import { get as getActions } from "../network/Actions";
import { get as getServices } from "../network/Services";
import { withCookies } from "react-cookie";
import Button from '@material-ui/core/Button'
import { Card, Message, Input, Rule, Form, Select, Grid, Switch } from 'shineout'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const service = 'policies';
const path = '';
const rules = Rule();
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class Policy extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history.location.state.data)
        this.state = {
            routes: [],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            methods: [],
            policy_name: this.props.history.location.state.data.name ? this.props.history.location.state.data.name : '',
            effect: this.props.history.location.state.data.effect ? this.props.history.location.state.data.effect : false,
            services: this.props.history.location.state.data.services ? this.props.history.location.state.data.services : [],
        };
    }

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
            }
        })
        getActions({}, result => {
            if (result != null) {
                var methods = [];
                result.map(data => methods.push(data.name))
                this.setState({ methods: methods })
            }
        })
        getServices({}, result => {
            if (result != null) {
                var services = [];
                result.map(data => services.push(data.name))
                this.setState({ service_list: services })
            }
        })
    }
    renderEmpty = onAppend => <Button onClick={() => onAppend({})}>Add data</Button>

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Card fullWidth style={{ textAlign: 'left' }}>
                    <Card.Body>
                        <h2>Policy Defination</h2>
                        <Form
                            value={{ name: this.state.policy_name, effect: this.state.effect, services: this.state.services }}
                            onSubmit={value => {
                                console.log(value)
                                var data = {};
                                if (this.state.policy_name == '') {
                                    this.state.POST ? create({
                                        name: value.name,
                                        effect: value.effect,
                                        services: value.services
                                    }, result => {
                                        if (result) {
                                            Message.success('Policy created', 3, { position: 'bottom-right' });
                                            this.setState({ policy_name: value.name })
                                        }
                                        else {
                                            Message.error('Failed to create policy. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                } else {
                                    this.state.PUT ? update({ name: this.state.policy_name }, {
                                        name: value.name,
                                        effect: value.effect,
                                        services: value.services
                                    }, result => {
                                        if (result) {
                                            Message.success('Policy updated', 3, { position: 'bottom-right' });
                                            this.setState({ policy_name: value.name })
                                        }
                                        else {
                                            Message.error('Failed to update policy. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                }
                            }}
                        >
                            <Grid gutter={1}
                                style={{
                                    display: "flex",
                                    alignItems: "left",
                                    padding: 'dense',
                                    marginBottom: 8,
                                }}>
                                <Grid width={0.80}>
                                    <Form.Item required label="Policy name">
                                        <Input
                                            name="name"
                                            fullWidth
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.20}>
                                    <Form.Item required label="Policy effect">
                                        <Switch name="effect" defaultValue={false} />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1}>
                                    <h4>Services</h4>
                                    <Form.Item required>
                                        <Form.FieldSet
                                            name="services"
                                            fullWidth
                                            empty={this.renderEmpty}
                                            value={this.state.routes}
                                        >
                                            {({ value, onAppend, onRemove }) => (
                                                <ExpansionPanel
                                                    name="name">
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">
                                                        <Typography className={classes.heading}>{value.name}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Card fullWidth style={{
                                                            display: "inline-block",
                                                            alignItems: "left",
                                                            padding: 'dense',
                                                            marginBottom: 8,
                                                            backgroundColor: '#CFE0E8'
                                                        }}>
                                                            <Card.Body>
                                                                <Grid gutter={1}
                                                                    style={{
                                                                        display: "block",
                                                                        alignItems: "left",
                                                                        padding: 'dense',
                                                                        marginBottom: 8
                                                                    }}
                                                                >
                                                                    <Grid width={0.40}>
                                                                        <Form.Item required label="Service name">
                                                                            <Select
                                                                                name="name"
                                                                                data={this.state.service_list}
                                                                                rules={[rules.required, rules.isExist]}
                                                                                fullWidth
                                                                                clearable
                                                                                keygen
                                                                                onCreate
                                                                                onFilter={text => d => d.indexOf(text) >= 0}
                                                                                single />
                                                                        </Form.Item>
                                                                    </Grid>

                                                                    <Grid width={0.2}>
                                                                        <a onClick={() => onAppend({})}>
                                                                            <i className="material-icons">add</i>
                                                                        </a>
                                                                        <a onClick={onRemove}>
                                                                            <i className="material-icons">close</i>
                                                                        </a>
                                                                    </Grid>
                                                                    <h4>Routes</h4>
                                                                    <Grid width={1}>
                                                                        <Form.Item required>
                                                                            <Form.FieldSet
                                                                                name="routes"
                                                                                fullWidth
                                                                                empty={this.renderEmpty}
                                                                                value={this.state.routes}
                                                                            >
                                                                                {({ onAppend, onRemove }) => (
                                                                                    <Card fullWidth style={{ backgroundColor: '#DAEBE8', }}>
                                                                                        <Card.Body>
                                                                                            <Grid gutter={1}
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    alignItems: "left",
                                                                                                    padding: 'dense',
                                                                                                    marginBottom: 8
                                                                                                }}>
                                                                                                <Grid width={1}>
                                                                                                    <Form.Item required label="Path name">
                                                                                                        <Input
                                                                                                            name="path"
                                                                                                            fullWidth
                                                                                                            value={this.state.path}
                                                                                                        />
                                                                                                    </Form.Item>
                                                                                                </Grid>
                                                                                                <h4>Methods</h4>
                                                                                                <Grid width={0.9}>
                                                                                                    <Form.Item required>
                                                                                                        <Form.FieldSet
                                                                                                            placeholder={'Methods'}
                                                                                                            name="methods"
                                                                                                            fullWidth
                                                                                                            empty={this.renderEmpty}
                                                                                                            value={this.state.data}
                                                                                                        >
                                                                                                            {({ onAppend, onRemove }) => (
                                                                                                                <Card fullWidth style={{ backgroundColor: '#B7D7E8', }}>
                                                                                                                    <Card.Body>
                                                                                                                        <Grid gutter={1}
                                                                                                                            style={{
                                                                                                                                display: "flex",
                                                                                                                                alignItems: "left",
                                                                                                                                padding: 'dense',
                                                                                                                                marginBottom: 8
                                                                                                                            }}>
                                                                                                                            <Grid width={0.8}>
                                                                                                                                <Form.Item required label="Method name">
                                                                                                                                    <Select
                                                                                                                                        name="name"
                                                                                                                                        data={this.state.methods}
                                                                                                                                        rules={[rules.required, rules.isExist]}
                                                                                                                                        fullWidth
                                                                                                                                        clearable
                                                                                                                                        keygen
                                                                                                                                        onCreate
                                                                                                                                        onFilter={text => d => d.indexOf(text) >= 0}
                                                                                                                                        single />
                                                                                                                                </Form.Item>
                                                                                                                            </Grid>
                                                                                                                            <Grid width={0.2}>
                                                                                                                                <a onClick={() => onAppend({})}>
                                                                                                                                    <i className="material-icons">add</i>
                                                                                                                                </a>
                                                                                                                                <a onClick={onRemove}>
                                                                                                                                    <i className="material-icons">close</i>
                                                                                                                                </a>
                                                                                                                            </Grid>
                                                                                                                            <h4>Resources</h4>
                                                                                                                            <Grid width={1}>
                                                                                                                                <Form.Item>
                                                                                                                                    <Form.FieldSet
                                                                                                                                        name="resources"
                                                                                                                                        fullWidth
                                                                                                                                        empty={this.renderEmpty}
                                                                                                                                        value={this.state.data}
                                                                                                                                    >
                                                                                                                                        {({ onAppend, onRemove }) => (
                                                                                                                                            <Card fullWidth style={{ backgroundColor: '#87BDD8', }}>
                                                                                                                                                <Card.Body>
                                                                                                                                                    <Grid gutter={1}
                                                                                                                                                        style={{
                                                                                                                                                            display: "flex",
                                                                                                                                                            alignItems: "left",
                                                                                                                                                            padding: 'dense',
                                                                                                                                                            marginBottom: 8
                                                                                                                                                        }}>
                                                                                                                                                        <Grid width={0.45}>
                                                                                                                                                            <Form.Item required>
                                                                                                                                                                <Input
                                                                                                                                                                    name="key"
                                                                                                                                                                    placeholder={'KEY'}
                                                                                                                                                                    fullWidth
                                                                                                                                                                />
                                                                                                                                                            </Form.Item>
                                                                                                                                                        </Grid>
                                                                                                                                                        <Grid width={0.45}>
                                                                                                                                                            <Form.Item required>
                                                                                                                                                                <Select
                                                                                                                                                                    name="value"
                                                                                                                                                                    fullWidth
                                                                                                                                                                    placeholder={'VALUES'}
                                                                                                                                                                    style={{ marginRight: 8 }}
                                                                                                                                                                    onCreate
                                                                                                                                                                    clearable
                                                                                                                                                                    keygen
                                                                                                                                                                    onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                                                                                                                                    multiple />
                                                                                                                                                            </Form.Item>
                                                                                                                                                        </Grid>
                                                                                                                                                        <Grid width={0.1}>
                                                                                                                                                            <a onClick={() => onAppend({})}>
                                                                                                                                                                <i className="material-icons">add</i>
                                                                                                                                                            </a>
                                                                                                                                                            <a onClick={onRemove}>
                                                                                                                                                                <i className="material-icons">close</i>
                                                                                                                                                            </a>
                                                                                                                                                        </Grid>
                                                                                                                                                    </Grid>
                                                                                                                                                </Card.Body>
                                                                                                                                            </Card>
                                                                                                                                        )}
                                                                                                                                    </Form.FieldSet>
                                                                                                                                </Form.Item>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                    </Card.Body>
                                                                                                                </Card>
                                                                                                            )}
                                                                                                        </Form.FieldSet>
                                                                                                    </Form.Item>
                                                                                                </Grid>
                                                                                                <Grid width={0.1}>
                                                                                                    <a onClick={() => onAppend({})}>
                                                                                                        <i className="material-icons">add</i>
                                                                                                    </a>
                                                                                                    <a onClick={onRemove}>
                                                                                                        <i className="material-icons">close</i>
                                                                                                    </a>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                )}
                                                                            </Form.FieldSet>
                                                                        </Form.Item>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card.Body>
                                                        </Card>

                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )}
                                        </Form.FieldSet>
                                    </Form.Item>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.POST || this.state.PUT ? <Card.Submit
                            style={{
                                marginTop: 20,
                                width: '100%',
                            }}
                        >Submit</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(withCookies(Policy));
