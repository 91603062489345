import superagent from 'superagent';

const URL = '/lm/v1/test_suite';

export const testOne = (test, callback) => {
    superagent.post(URL)
        .send(test)
        .end((err, response) => {
            console.log(response)
            if (err) {
                callback(false);
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};

export const testMany = (test, callback) => {
    superagent.post(URL + '/multiple')
        .send({ test: test })
        .end((err, response) => {
            console.log(response)
            if (err) {
                callback(undefined);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else {
                    callback(undefined)
                }
            }
        });
};
