import React from 'react';
import { create, update } from "../network/Users";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { get as getPolicy } from "../network/Policies";
import { Card, Message, Input, Rule, Form, Select, Grid, Button } from 'shineout'
import Map from '../../commons/Map'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA2nhQ7EtCRjvJBgc1pzY1YuzIRC4dNuVM&v=3.exp&libraries=geometry,drawing';

const service = 'users';
const path = '';
const rules = Rule();
class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            policies: JSON.parse(this.props.cookies.cookies.policy),
            username: this.props.cookies.cookies.username,
            POST: false,
            PUT: false,
            POST_DATA: [],
            PUT_DATA: [],
            policies: [],
            isOpenDialog: false,
            user_data: this.props.history.location.state.data ? this.props.history.location.state.data : undefined,
            centerLat: 19.0786894,
            centerLng: 72.8359967,
        }
    };

    getCurrentPosition() {
        const currentPosition = new window.google.maps.LatLng(this.state.center.lat, this.state.center.lng);
        return currentPosition;
    }

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                    var policies = method.resources.find(e => e.key == 'policies')
                    if (policies) {
                        if (policies.value[0] == '*') {
                            getPolicy({}, result => {
                                var policyList = []
                                result.map(e => policyList.push(e.name))
                                this.setState({ policies: policyList })
                            })
                        } else {
                            this.setState({ policies: policies.value })
                        }
                    }
                })
            }
        })
    }

    renderEmpty = onAppend => <Button onClick={() => onAppend({})}>Add data</Button>

    render() {
        return (
            <React.Fragment>
                <Card >
                    <Card.Header>
                        <h2>User Details</h2>
                    </Card.Header>
                    <Card.Body style={{
                        'text-align': 'left',
                    }}>
                        <Form
                            disabled={!this.state.POST}
                            value={this.state.user_data}
                            onSubmit={value => {
                                console.log(value)
                                var data = {
                                    name: value.name,
                                    password: value.password,
                                    parent: value.parent ? value.parent : this.state.username,
                                    policy: value.policy,
                                    androidId: value.androidId,
                                    contactNo: value.contactNo,
                                    geoFences: value.geoFences,
                                }

                                if (this.state.user_data == undefined) {
                                    this.state.POST ? create(data, result => {
                                        if (result) {
                                            Message.success('User created', 3, { position: 'bottom-right' });
                                            this.setState({ user_data: data })
                                        } else {
                                            Message.error('Operation failed', 3, { position: 'bottom-right' });
                                        }
                                    }) : Message.error('Permission denied', 3, { position: 'bottom-right' })
                                } else {
                                    this.state.PUT ? update(this.state.user_data, data, result => {
                                        if (result) {
                                            Message.success('User updated', 3, { position: 'bottom-right' });
                                            this.setState({ user_data: data })
                                        } else {
                                            Message.error('Operation failed', 3, { position: 'bottom-right' });
                                        }
                                    }) : Message.error('Permission denied', 3, { position: 'bottom-right' })
                                }
                            }}>
                            <Grid gutter={2}>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Username">
                                        <Input
                                            name="name"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Password">
                                        <Input.Password
                                            name="password"
                                            type="password"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item required label="Policy">
                                        <Select
                                            name="policy"
                                            data={this.state.policies}
                                            rules={[rules.required, rules.isExist]}
                                            clearable
                                            keygen
                                            onFilter={text => d => d.indexOf(text) >= 0}
                                            single />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Android Id">
                                        <Input
                                            name="androidId"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid width={0.5}>
                                    <Form.Item
                                        required label="Contact no">
                                        <Input
                                            name="contactNo"
                                            placeholder="Ex: +919876543210"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Form.Item
                                    required label="Latitude">
                                    <Input.Number
                                        name="centerLat"
                                        defaultValue={this.state.centerLat}
                                        placeholder={"Ex: 22.2"}
                                        onChange={value => this.setState({ centerLat: value })}
                                    />
                                    <Input.Number
                                        name="centerLng"
                                        defaultValue={this.state.centerLng}
                                        placeholder={"Ex: 72.3"}
                                        onChange={value => this.setState({ centerLng: value })}
                                    />
                                </Form.Item>
                                <Grid width={0.9}>
                                    <Form.Item required>
                                        <Form.FieldSet
                                            placeholder={'GeoFences'}
                                            name="geoFences"
                                            fullWidth
                                            empty={this.renderEmpty}
                                            value={this.state.data}
                                        >
                                            {({ value, onAppend, onRemove }) => (
                                                <Card fullWidth>
                                                    <Card.Body>
                                                        <Grid gutter={1}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "left",
                                                                padding: 'dense',
                                                                marginBottom: 8
                                                            }}>
                                                            <Grid width={0.8}>
                                                                <Form.Item
                                                                    required label="Name">
                                                                    <Input
                                                                        name="name"
                                                                    />
                                                                </Form.Item>
                                                            </Grid>
                                                            <Grid width={0.2}>
                                                                <a onClick={() => onAppend({})}>
                                                                    <i className="material-icons">add</i>
                                                                </a>
                                                                <a onClick={onRemove}>
                                                                    <i className="material-icons">close</i>
                                                                </a>
                                                            </Grid>
                                                            <Grid width={0.9}>
                                                                <ExpansionPanel
                                                                    name="name">
                                                                    <ExpansionPanelSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header">
                                                                        <Typography >Co-ordinates</Typography>
                                                                    </ExpansionPanelSummary>
                                                                    <Form.Item required>
                                                                        <Form.FieldSet
                                                                            placeholder={'Polygon'}
                                                                            name="polygon"
                                                                            fullWidth
                                                                            empty={this.renderEmpty}
                                                                        >
                                                                            {({ onAppend, onRemove }) => (
                                                                                <Grid gutter={1}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "left",
                                                                                        padding: 'dense',
                                                                                    }}>
                                                                                    <Grid width={0.4}>
                                                                                        <Form.Item
                                                                                            required label="Latitude">
                                                                                            <Input
                                                                                                name="lat"
                                                                                                placeholder="Latitude"
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Grid>
                                                                                    <Grid width={0.4}>
                                                                                        <Form.Item
                                                                                            required label="Longitude">
                                                                                            <Input
                                                                                                name="lng"
                                                                                                placeholder="Longitude"
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Grid>
                                                                                    <Grid width={0.2}>
                                                                                        <a onClick={() => onAppend({})}>
                                                                                            <i className="material-icons">add</i>
                                                                                        </a>
                                                                                        <a onClick={onRemove}>
                                                                                            <i className="material-icons">close</i>
                                                                                        </a>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </Form.FieldSet>
                                                                    </Form.Item>
                                                                </ExpansionPanel>
                                                            </Grid>
                                                            <Grid width={0.1}>
                                                                <a onClick={() => this.setState({ isOpenDialog: true, tempCoordinates: value.polygon })}>
                                                                    <i className="material-icons">map</i>
                                                                </a>
                                                            </Grid>
                                                            <Dialog aria-labelledby="customized-dialog-title"
                                                                open={this.state.isOpenDialog}
                                                                googleMapURL={googleMapURL}
                                                                onClose={() => this.setState({ isOpenDialog: false })}
                                                                fullWidth
                                                                maxWidth={'md'}
                                                            >
                                                                <DialogTitle id="customized-dialog-title">Geofense builder</DialogTitle>
                                                                <DialogContent dividers>
                                                                    <Map
                                                                        googleMapURL={googleMapURL}
                                                                        center={{
                                                                            lat: this.state.centerLat,
                                                                            lng: this.state.centerLng,
                                                                        }}
                                                                        loadingElement={
                                                                            <p>Loading maps...</p>
                                                                        }
                                                                        containerElement={
                                                                            <div
                                                                                style={{ width: 910, height: 400 }}
                                                                                className="map-container" />
                                                                        }
                                                                        mapElement={
                                                                            <div
                                                                                style={{ width: 910, height: 400 }}
                                                                                className="map" />
                                                                        }
                                                                        doneDrawing={(polygon) => {
                                                                            var fence = new window.google.maps.Polygon({
                                                                                paths: polygon.getPaths(),
                                                                            })
                                                                            var coordinates = []
                                                                            var vertices = fence.getPath()
                                                                            for (var i = 0; i < vertices.getLength(); i++) {
                                                                                var xy = vertices.getAt(i);
                                                                                coordinates.push({
                                                                                    lat: xy.lat(),
                                                                                    lng: xy.lng()
                                                                                })
                                                                            }
                                                                            console.log(coordinates)
                                                                            this.setState({ tempCoordinates: coordinates })
                                                                        }}
                                                                    />
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <a onClick={() => {
                                                                        value.polygon = this.state.tempCoordinates
                                                                        this.setState({ isOpenDialog: false })
                                                                    }}>
                                                                        <i className="material-icons">save</i>
                                                                    </a>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </Grid>
                                                    </Card.Body>
                                                </Card>)}
                                        </Form.FieldSet>
                                    </Form.Item>
                                </Grid>
                            </Grid>
                        </Form>
                        {this.state.POST ? <Card.Submit
                            style={{
                                width: '100%',
                            }}
                        >Save</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}
export default withCookies(User);
