import superagent from 'superagent';

const URL = '/lm/v1/csvbundle_uploader';

export const csvbundleUploader = (auth_body, callback) => {
    superagent.post(URL)
        .send(auth_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(response)
        });
};
