import React from 'react';
import './App.css';
import Auth from "./components/auth/Auth";
import { withCookies } from "react-cookie";
import Dashboard from "./components/dashboard/Dashboard";
import { logout } from './components/auth/Login';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: <Auth onComplete={this.handleOnAuth} />,
        };
        this.handleOnAuth = this.handleOnAuth.bind()
    }
    componentDidMount() {
        if (process.env.NODE_ENV != 'development') {
            console.log = function () { }
        }
        if (this.props.cookies.cookies.policy !== undefined) {
            this.setState({ content: <Dashboard /> })
        } else {
            this.setState({ content: <Auth onComplete={this.handleOnAuth} /> })
        }
        var isSet = false
        var token = undefined
        setInterval(() => {
            var temp = []
            document.cookie.split(';').map(e => {
                var t = e.split('=')
                temp[t[0].trim()] = t[1]
            })
            if (temp.token != undefined && token == undefined) {
                token = temp.token
                isSet = true
            }
            console.log(temp.token)
            if (temp.token != token) {
                console.log('logout')
                logout()
            }
        }, 1000)
    }

    handleOnAuth = res => {
        this.props.cookies.set('policy', res.policy, {
            path: '/',
            maxAge: 10800
        });
        this.setState({ content: <Dashboard /> })
    };

    render() {
        return (
            <div className="App">
                {this.state.content}
            </div>
        );
    }
}
export default withCookies(App);