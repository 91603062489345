import React from 'react';
import '../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { withCookies } from "react-cookie";
import { routePermissions } from "../../auth/network/Login";
import { bundleUpdate } from "../network/Authwalls";
import { get as getResources } from "../network/Resources";
import { get as getFeatures } from "../network/Features";
import { Form, Card, Message, Upload } from 'shineout'

const service = 'uploader';
// const path = ['m', 'u'];
const path = 'a';
const UPLOAD_URL = '/lm/v1/uploader/a';
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class BundleUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: 'EMAC', field: 'emac' },
                { title: 'MBOARD', field: 'mboard' },
                { title: 'SERIAL NO', field: 'serialno' },
                { title: 'MODEL', field: 'model' },
                { title: 'BRAND', field: 'brand' },
                { title: 'FEATURE', field: 'feature' }             
            ],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            xlsx_data: [],
            table: '',
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
        };
    }

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            console.log(result)
            if (result === null) {
                Message.error('No permission to access this route', 3, { position: 'bottom-right' });
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.POST) {
                    getResources({ type: 'brand' }, (data) => {
                        console.log(data)
                        var brands = [];
                        data.map(brand_element => {
                            brands.push(brand_element.name)
                            this.setState({ brands: brands })
                        })
                    })
                    getResources({ type: 'mboard' }, (data) => {
                        console.log(data)
                        var boards = [];
                        data.map(board_element => {
                            boards.push(board_element.name)
                            this.setState({ boards: boards })
                        })
                    })
                    getResources({ type: 'attribute' }, (data) => {
                        console.log(data)
                        var attributes = [];
                        data.map(attribute_element => {
                            attributes.push(attribute_element.name)
                            this.setState({ attributes: attributes })
                        })
                    })
                    getFeatures({}, (data) => {
                        console.log('FEATURES ADDED------->' + data)
                        console.log(data)
                        var features = [];
                        data.map(features_element => {
                            features.push(features_element.name)
                            this.setState({ features: features })
                        })
                        /*
                        data.map(e => features.push(e.name))
                        this.setState({ features: features })
                        */
                    });

                }
            }
        })
    }

    render() {
        return (
            <React.Fragment >
                <Card fullWidth>
                    <Card.Header>Bundle Update</Card.Header>
                    {this.state.POST ? <Card.Body>
                        <Form
                            scrollToError={30}
                            onSubmit={data => {
                                var request_data = {
                                    state: '1',
                                    description: '',
                                    emacs: this.state.xlsx_data
                                }
                                console.log(request_data)
                                bundleUpdate(request_data, status => {
                                    if (status) {
                                        Message.success('Records updated', 3, { position: 'bottom-right' });
                                    } else {
                                        Message.error('Failed updating data. Try again', 3, { position: 'bottom-right' });
                                    }

                                })
                            }}>
                            <Upload
                                action={UPLOAD_URL}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                name="file"
                                // headers={{ Authorization: 'Bearer ' + this.state.token }}
                                params={{ brand: this.state.brands, board: this.state.boards, features: this.state.features, attribute: this.state.attributes }}
                                htmlName={'file'}
                                limit={1}
                                renderResult={f => f}
                                onSuccess={(res, file, data) => {
                                    res = JSON.parse(res);
                                    console.log(res);
                                    if (res.errorCode) {
                                        if (res.errorCode >= 105) {
                                            this.setState({ data: res.data })
                                            this.setState({
                                                table: <MaterialTable
                                                    title={res.errorMessage + '!! Fix entries below to continue'}
                                                    columns={this.state.columns}
                                                    data={this.state.data}
                                                    onRowClick={(event, rowData, toggleDetailPanel) => {
                                                        console.log(rowData)
                                                    }}
                                                    options={{
                                                        pageSize: res.data.length < 100 ? res.data.length : 100,
                                                        pageSizeOptions: [20, 50, 100, 200, 300, 500],
                                                        actionsColumnIndex: 100,
                                                    }}
                                                />
                                            })
                                            return new Error()
                                        }else if(res.errorCode == 103){
                                            this.setState({ table: '' })
                                            return new Error(res.errorMessage)
                                        }
                                         else {
                                            this.setState({ table: '' })
                                            return new Error(res.errorMessage)
                                        }
                                    } else {
                                        this.setState({ xlsx_data: res })
                                        this.setState({ data: [] })
                                        this.setState({ table: '' })
                                        return 'Data parsed, Total entries: ' + res.length
                                    }

                                }}
                                validator={
                                    {
                                        ext: ext => (['xls', 'xlsx'].includes(ext) ?
                                            undefined :
                                            new Error('File extension must be xls or xlsx')),
                                    }
                                }>
                                <Upload.Dragger>
                                    <i className="material-icons">cloud_upload</i>
                                    <br />
                                    Click or Drag application to upload
                                    <br />
                                    Upload Excel file
                                </Upload.Dragger>
                            </Upload>
                        </Form>
                        {this.state.table}
                    </Card.Body> : ''}
                    <Card.Footer>
                        <div style={{ textAlign: 'left' }}>
                            Download XLSX file template <a href="/template_update.xlsx">here</a>
                        </div>
                        {this.state.POST ? <Card.Submit
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 100,
                            }}
                        >Submit</Card.Submit> : ''}
                    </Card.Footer>
                </Card>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(withCookies(BundleUpdate));
