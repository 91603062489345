import React from 'react';
import '../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { create, update, get, delet } from "../network/Firmwares";
import { get as getResources } from "../network/Resources";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Message } from 'shineout'

const service = 'firmwares';
const path = '';
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class FirmwaresList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: 'Friendly name', field: 'friendly_name' },
                { title: 'Brand', field: 'brand' },
                { title: 'Board', field: 'board' },
                { title: 'Mac range', field: 'mac_range' },
                { title: 'Launcher type', field: 'launcher_type' },
                { title: 'Firmware date', field: 'firmware_date' },
                { title: 'File name', field: 'firmware_file_name' },
                { title: 'Download url', field: 'download_url' },
                { title: 'Panel', field: 'panel' },
                { title: 'Size', field: 'size' },
                { title: 'Remote', field: 'remote' },
                { title: 'Star rating', field: 'star_rating' },
                { title: 'Open cell', field: 'open_cell' },
                { title: 'BLU', field: 'blu' },
                { title: 'LED', field: 'led' },
                { title: 'Speaker', field: 'speaker' },
                { title: 'Change log', field: 'change_log' },
                { title: 'Remarks', field: 'remarks' },
                { title: 'Batch', field: 'batch' },
            ],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            brands: [],
            mboards: []
        };
    }

    componentDidMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
                if (this.state.GET) {
                    getResources({ type: 'brand' }, (data) => {
                        console.log(data)
                        this.setState({ brands: data });
                    });
                    getResources({ type: 'mboard' }, (data) => {
                        console.log(data)
                        this.setState({ mboards: data });
                    });
                    get({}, (data) => {
                        console.log(data)
                        this.setState({ data: data });
                        this.setState({ isLoading: false })
                    });
                }
            }
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <MaterialTable
                    title={'Firmwares'}
                    columns={this.state.columns}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        loadingType: 'overlay',
                        addRowPosition: 'first',
                        actionsColumnIndex: 100,
                        pageSize: 20,
                    }}
                    editable={{
                        onRowAdd: this.state.POST ? (newData) =>
                            new Promise(resolve => {
                                create(newData, result => {
                                    if (result)
                                        get({}, (data) => {
                                            this.setState({ data: data });
                                            Message.success('Firmware created', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to create firmware. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                        onRowUpdate: this.state.PUT ? (newData, oldData) =>
                            new Promise(resolve => {
                                update(oldData, newData, result => {
                                    if (result)
                                        get({}, (data) => {
                                            this.setState({ data: data });
                                            Message.success('Firmware updated', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to delete firmware. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                        onRowDelete: this.state.DELETE ? oldData =>
                            new Promise(resolve => {
                                delet(oldData, result => {
                                    if (result)
                                        get({}, (data) => {
                                            this.setState({ data: data });
                                            Message.success('Firmware deleted', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to delete firmware. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withCookies(FirmwaresList));
