import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { create, update } from "../network/Services";
import { routePermissions } from "../../auth/network/Login";
import { get as getActions } from "../network/Actions";
import { withCookies } from "react-cookie";
import Button from '@material-ui/core/Button'
import { Card, Message, Input, Rule, Form, Select, Grid, Switch } from 'shineout'

const service = 'services';
const path = '';
const rules = Rule();
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class Service extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history.location.state.data)
        this.state = {
            routes: [],
            data: [],
            policy: JSON.parse(this.props.cookies.cookies.policy),
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            methods: [],
            service_name: this.props.history.location.state.data.name ? this.props.history.location.state.data.name : '',
            routes: this.props.history.location.state.data.routes ? this.props.history.location.state.data.routes : [],
        };
    }

    componentWillMount() {
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                })
            }
        })
        getActions({}, result => {
            if (result != null) {
                var methods = [];
                result.map(data => methods.push(data.name))
                this.setState({ methods: methods })
            }
        })
    }
    renderEmpty = onAppend => <Button onClick={() => onAppend({})}>Add data</Button>

    render() {
        return (
            <React.Fragment>
                <Card fullWidth>
                    <Card.Body>
                        <h2>Service Defination</h2>
                        <Form
                            value={{ name: this.state.service_name, routes: this.state.routes }}
                            onSubmit={value => {
                                console.log(value)
                                var data = {};
                                if (this.state.service_name == '') {
                                    this.state.POST ? create({
                                        name: value.name,
                                        routes: value.routes
                                    }, result => {
                                        if (result) {
                                            Message.success('Service created', 3, { position: 'bottom-right' });
                                            this.setState({ service_name: value.name })
                                        }
                                        else {
                                            Message.error('Failed to create service. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                } else {
                                    this.state.PUT ? update({ name: this.state.service_name }, {
                                        name: value.name,
                                        routes: value.routes
                                    }, result => {
                                        if (result) {
                                            Message.success('Service updated', 3, { position: 'bottom-right' });
                                            this.setState({ service_name: value.name })
                                        }
                                        else {
                                            Message.error('Failed to update service. Try again', 3, { position: 'bottom-right' });
                                        }
                                    }) : console.log('Permission denied')
                                }
                            }}
                        >
                            <Form.Item required label="Service name">
                                <Input
                                    name="name"
                                    fullWidth
                                />
                            </Form.Item>
                            <Form.Item required label="Routes">
                                <Form.FieldSet
                                    rules={[rules.min(1), rules.isExist]}
                                    name="routes"
                                    fullWidth
                                    empty={this.renderEmpty}
                                    value={this.state.routes}
                                >
                                    {({ onAppend, onRemove }) => (
                                        <Card fullWidth style={{ backgroundColor: '#b7d7e8', }}>
                                            <Card.Body>
                                                <Grid gutter={1}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "left",
                                                        marginBottom: 12
                                                    }}>
                                                    <Grid width={1}>
                                                        <Form.Item required label="Path">
                                                            <Input
                                                                name="path"
                                                                fullWidth
                                                                value={this.state.path}
                                                            />
                                                        </Form.Item>
                                                    </Grid>
                                                    <Grid width={0.9}>
                                                        <Form.Item required label="Methods">
                                                            <Form.FieldSet
                                                                rules={[rules.min(1), rules.isExist]}
                                                                name="methods"
                                                                fullWidth
                                                                empty={this.renderEmpty}
                                                                value={this.state.data}
                                                            >
                                                                {({ onAppend, onRemove }) => (
                                                                    <Card fullWidth style={{ backgroundColor: '#87bdd8', }}>
                                                                        <Card.Body>
                                                                            <Grid gutter={1}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "left",
                                                                                    marginBottom: 12
                                                                                }}>
                                                                                <Grid width={0.8}>
                                                                                    <Form.Item required label="Method name">
                                                                                        <Select
                                                                                            name="name"
                                                                                            data={this.state.methods}
                                                                                            rules={[rules.required, rules.isExist]}
                                                                                            fullWidth
                                                                                            clearable
                                                                                            keygen
                                                                                            onCreate
                                                                                            onFilter={text => d => d.indexOf(text) >= 0}
                                                                                            single />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                                <Grid width={0.2}>
                                                                                    <a style={{ margin: '0 12px' }} onClick={() => onAppend({})}>
                                                                                        <i className="material-icons">add</i>
                                                                                    </a>
                                                                                    <a onClick={onRemove}>
                                                                                        <i className="material-icons">close</i>
                                                                                    </a>
                                                                                </Grid>

                                                                                <Grid width={0.5}>
                                                                                    <Form.Item required label="Authentication">
                                                                                        <Switch name="authentication" defaultValue={false} />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                                <Grid width={0.5}>
                                                                                    <Form.Item required label="Authorization">
                                                                                        <Switch name="authorization" defaultValue={false} />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                                <Grid width={0.5}>
                                                                                    <Form.Item required label="Headers">
                                                                                        <Select
                                                                                            name="headers"
                                                                                            fullWidth
                                                                                            style={{ marginRight: 8 }}
                                                                                            onCreate
                                                                                            clearable
                                                                                            keygen
                                                                                            onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                                                            multiple />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                                <Grid width={0.5}>
                                                                                    <Form.Item required label="Params">
                                                                                        <Select
                                                                                            name="params"
                                                                                            fullWidth
                                                                                            style={{ marginRight: 8 }}
                                                                                            onCreate
                                                                                            clearable
                                                                                            keygen
                                                                                            onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                                                            multiple />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                                <Grid width={0.5}>
                                                                                    <Form.Item required label="Query">
                                                                                        <Select
                                                                                            name="query"
                                                                                            fullWidth
                                                                                            style={{ marginRight: 8 }}
                                                                                            onCreate
                                                                                            clearable
                                                                                            keygen
                                                                                            onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                                                            multiple />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                                <Grid width={0.5}>
                                                                                    <Form.Item required label="Body">
                                                                                        <Select
                                                                                            name="body"
                                                                                            fullWidth
                                                                                            style={{ marginRight: 8 }}
                                                                                            onCreate
                                                                                            clearable
                                                                                            keygen
                                                                                            onFilter={text => d => d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
                                                                                            multiple />
                                                                                    </Form.Item>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card.Body>
                                                                    </Card>
                                                                )}
                                                            </Form.FieldSet>
                                                        </Form.Item>
                                                    </Grid>
                                                    <Grid width={0.1}>
                                                        <a style={{ margin: '0 12px' }} onClick={() => onAppend({})}>
                                                            <i className="material-icons">add</i>
                                                        </a>
                                                        <a onClick={onRemove}>
                                                            <i className="material-icons">close</i>
                                                        </a>
                                                    </Grid>
                                                </Grid>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Form.FieldSet>
                            </Form.Item>
                        </Form>
                        {this.state.POST || this.state.PUT ? <Card.Submit
                            style={{
                                marginTop: 20,
                                width: '100%',
                            }}
                        >Submit</Card.Submit>
                            : ''}
                    </Card.Body>
                </Card>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(withCookies(Service));
