import superagent from "superagent";

const URL = "/lm/v1/logs";

export const get = (query, callback) => {
	superagent
		.get(URL)
		.query(query)
		.end((err, response) => {
			if (err) {
				callback([]);
				console.log("Request failed");
			} else {
				if (response.status === 200) {
					callback(response.body);
				} else {
					callback([]);
					console.log("No data available");
				}
			}
			console.log(response);
		});
};
