import React from 'react';
import { withCookies } from "react-cookie";
import MaterialTable from 'material-table';
import { getAnalytics } from '../network/Launcherlmslists';
import withStyles from "@material-ui/core/styles/withStyles";
import { colors } from '@material-ui/core';

const styles = theme => ({
    theme: theme,
    root: {
        display: 'flex',
    },
});

const outerdiv = {
    widt: "100%",
    //backgroundColor: "DodgerBlue",
    overflow: "hidden",
};

const leftdiv = {
    width: "50%",
    //backgroundColor: "red",
    float: "left",
};

const rightdiv = {
    marginLeft: "50%",
    //backgroundColor: "green",
};

class Launcherlmslist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: '',
            open: false,
            data: [],
            showLoading: true,
            // data: { brand: [], model: [], mboard: [] },
            policies: this.props.cookies.cookies.policy != undefined ? JSON.parse(this.props.cookies.cookies.policy) : null,
        };
        console.log(this.props.cookies);
    }

    componentDidMount() {
        getAnalytics(data => {
            console.log(data.literesult)
            console.log(data.regresult)
            if (data.literesult != null) {
                var total = 0;
                data.literesult.map(row => {
                    total += row.total
                })
                data.literesult.push({ _id: 'Total', total: total })
                this.setState({ datalite: data.literesult, showLoading: false })
                console.log(this.state.datalite)

            }
            if (data.regresult != null) {
                var total = 0;
                data.regresult.map(row => {
                    total += row.total
                })
                data.regresult.push({ _id: 'Total', total: total })
                this.setState({ datareg: data.regresult, showLoading: false })
                console.log(this.state.datareg)
            }
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div style={outerdiv}>
                    <div style={leftdiv}> <MaterialTable
                        style={{ width: "50% !important" }}
                        title={'Regular Launcher'}
                        isLoading={this.state.showLoading}
                        options={
                            {
                                exportAllData: true,
                                exportButton: true,
                                exportDelimiter: ',',
                                exportFileName: 'Regular Launcher_List_Analysis',
                                pageSize: 50,
                                loadingType: "overlay",
                            }
                        }
                        columns={[
                            { title: 'Brand', field: '_id' },
                            { title: 'Total', field: 'total' },
                        ]}

                        data={this.state.datareg}
                    />
                    </div>
                    <div style={rightdiv}> <MaterialTable
                        style={{ width: "50% !important" }}
                        title={'LITE Launcher'}
                        isLoading={this.state.showLoading}
                        options={
                            {
                                exportAllData: true,
                                exportButton: true,
                                exportDelimiter: ',',
                                exportFileName: 'LITE Launcher_List_Analysis',
                                pageSize: 50,
                                loadingType: "overlay",
                            }
                        }
                        columns={[
                            { title: 'Brand', field: '_id' },
                            { title: 'Total', field: 'total' },
                        ]}

                        data={this.state.datalite}
                    />
                    </div>

                </div>

            </React.Fragment >

        );
    }
}
export default withStyles(styles)(withCookies(Launcherlmslist));