import React from 'react';
import '../../App.css';
import { withStyles } from "@material-ui/core";
import Login from "./Login";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Fab from '@material-ui/core/Fab';
import Android from '@material-ui/icons/Android';
import Popover from '@material-ui/core/Popover';
var app_url = "";
const styles = theme => ({
    menuButton: {
        marginRight: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
});

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            component: <div />,
        }
        this.handleOnLogin = this.handleOnLogin.bind()
        this.handleDoLogin = this.handleDoLogin.bind()
    }

    componentDidMount() {
        this.setState({
            component: <Login
                onLogin={this.handleOnLogin}
            />
        })
    }

    handleOnLogin = res => {
        console.log('handleOnLogin: ' + res)
        this.props.onComplete(res)
    }

    handleDoLogin = res => {
        console.log('handleOnRegister: ' + res)
        this.setState({
            component: <Login
                onForgotPassword={this.handleDoForgotPassword}
                onLogin={this.handleOnLogin}
            />,
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <header className="App-header">
                    <AppBar position="fixed">
                        <Toolbar>
                            <Typography color="inherit" align="center">
                                Cloudwalker License Management System
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </header>
               
                <main className="App-main">
                    <Box mt={2} mb={10}>
                        {this.state.component}
                         {/* 
                        <Fab
                            aria-label={"Download App"}
                            aria-haspopup="true"
                            className={classes.fab} color={"inherit"}
                            onMouseEnter={(event) => {
                                this.setState({ anchorEl: event.currentTarget });
                            }}
                            href={app_url}
                            onMouseLeave={() => {
                                this.setState({ anchorEl: null });
                            }}>
                            <Android />
                        </Fab>
                       
                        <Popover
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            className={classes.popover}
                            classes={{ paper: classes.paper }}
                            open={this.state.anchorEl ? 'mouse-over-popover' : undefined}
                            anchorEl={this.state.anchorEl}>
                            Click to download auth app
                        </Popover>*/}
                    </Box>
                </main> 
                <footer className="App-footer">
                    <Box mt={2} mb={2}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'All rights reserved at '}
                            <br />
                            <Link color="inherit" href="http://www.cloudwalker.tv">Cloudwalker Streaming Technologies, Mumbai</Link>
                        </Typography>
                    </Box>
                </footer>
            </div>
        );
    }
}
export default withStyles(styles)(Auth);
