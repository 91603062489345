import superagent from 'superagent';

const URL = '/lm/v1/reports';

export const generate = (filters, callback) => {
    superagent.post(URL)
        .send(filters)
        .end((err, response) => {
            console.log(response)
            if (err) {
                callback(null);
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else {
                    callback(null)
                }
            }
        });
};
