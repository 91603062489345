import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { routePermissions } from "../../auth/network/Login";
import { get } from "../network/Logs";
import { withCookies } from "react-cookie";
import MaterialTable from "material-table";
import { Message } from "shineout";

const service = "logs";
const path = "";
const styles = (theme) => ({
	theme: theme,
	root: {
		display: "wrap",
	},
	container: {
		display: "block",
		flexWrap: "wrap",
	},
	fab: {
		margin: theme.spacing(1),
	},
	box: {
		component: "div",
		display: "inline",
		flexWrap: "wrap",
	},
	card: {
		padding: theme.spacing(1),
		margin: theme.spacing(2),
	},
	textField: {
		margin: 8,
		padding: theme.spacing(1),
		width: 200,
	},
	media: {
		paddingTop: "56.25%",
	},
});

class Logs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			query: [],
			data: [],
			policies: JSON.parse(this.props.cookies.cookies.policy),
			GET: false,
			field: [],
			rule_data: {},
			filter_data: [],
			date_range: [],
			columns: [
				{ title: "Username", field: "username" },
				{ title: "Policy", field: "policy" },
				{ title: "Path", field: "client.path" },
				{ title: "Method", field: "client.method" },
				{ title: "Assets", field: "assets" },
			],
		};
	}

	componentWillMount() {
		console.log({ service: service, path: path });
		routePermissions(
			this.props,
			{ service: service, path: path },
			(result) => {
				if (result === null) {
					this.setState({ isError: true });
				} else {
					result.map((method) => {
						this.setState({ [method.name]: true });
						this.setState({ [method.name + "_DATA"]: method.resources });
					});
					if (!this.state.GET) {
						Message.error("No permission to view this page", 3, {
							position: "bottom-right",
						});
					} else {
						get({}, (data) => {
							data.map((log) => {
								var temp = "";
								log.assets.map((board) => (temp += board + "\n"));
								log.assets = temp;
							});
							this.setState({ data: data });
						});
					}
				}
			}
		);
	}

	render() {
		return (
			<div>
				<MaterialTable
					title={"Logs"}
					columns={this.state.columns}
					data={this.state.GET ? this.state.data : []}
					isLoading={this.state.isLoading}
					options={{
						loadingType: "overlay",
						pageSize: 20,
						padding: "dense",
						filtering: true,
						grouping: true,
					}}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(withCookies(Logs));
